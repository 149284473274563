import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button, AccordionDetails, Alert, Icon } from "@mui/material";
import { useLocation } from "react-router-dom";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { v4 as uuid } from "uuid";

import RHFieldWrapper from "components/common/RHFieldWrapper";
import RHConfirmDialog from "components/common/RHConfirmDialog";
import RHAccordion from "../RHAccordion";
import FormField from "./FormField";
import HistoryView from "../HistoryView";

import Tooltip from "@mui/material/Tooltip";

import ToolBar from "./ToolBar";
import { useTableStyles } from "./styles";
import {
  updateNullableParentsInPayload,
  prepareJsonForTransport,
  transformObjectProperties,
  restructureObject,
  normalizeAndRemoveKeys,
} from "./itemProcessingHelpers";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { apiRequest, handleTableData, useCustomMutation, useApiToken } from "common/apiUtils";

import { useDataContext } from "contexts/DataContext";

import {
  capitalizeFirstLetter,
  getErrorMessage,
  getGeneralFormStylesSx,
  scrollToBottom,
  extractIdsFromPath,
  createOrderedDataGridColumns,
  createColumnVisibilityModel,
  parseErrorObject,
  collectRequiredKeys,
} from "./tableHelpers";

export default function RHEditableTable_v2(props) {
  const {
    rowKey,
    tableData,
    setNotificationProps,
    friendlyNameKey,
    retrieveSelectedRows,
    retrieveNewItemAsSelectedRow,
    onSelectedRowsChanged,
    onFilterChangeHandler,
    rowCount,
    onPaginationChanged,
    onSortModelChanged,
    disableEditMode,
    detailTitleOverride,
    searchOrCreateText,
    hideSearchBoxTitle,
    isRowEditable,
    disableRowClick,
    onCancel,
    onEdit,
    additionalEditButtons,
    onImport,
    onExport,
    onDocs,
    visibleColumnData,
    pageData,
    sandbox,
    friendlyName,
    paginationModel,
    sortModel,
    onClearSettings,
    customQueryProps,
    customQueryData,
    shouldCopy,
    loadDefaults,
    customDefaultId,
    hasEditPermission,
    enableSearchByName,
    onHistory,
    historyView,
    selectedSchemaObject,
    historyFilter,
    enableSmartSearch,
  } = props;
  const [selectedItem, setSelectedItem] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [newItemCreated, setNewItemCreated] = useState(false);
  const [editDetail, setEditDetail] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  const [errorMsg, setErrorMsg] = useState();

  const [initialState, setInitialState] = useState(null);
  const [columns, setColumns] = useState(null);
  const [formColumns, setFormColumns] = useState(null);
  const [newModelArgs, setNewModelArgs] = useState([]);
  const [forceRemountKey, setForceRemountKey] = useState(Date.now());
  const [resetFlag, setResetFlag] = useState(Date.now());
  const [selectedInlineRows, setSelectedInlineRows] = useState();
  const [editableRows, setEditableRows] = useState();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [nulledParents, setNulledParents] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [filterValue, setFilterValue] = useState(null);
  const location = useLocation();

  const { dataContext } = useDataContext();
  const schemaObjects = dataContext.schemaObjects;

  const isEditModeDisabled = disableEditMode === undefined ? false : disableEditMode;

  const gridApiRef = useGridApiRef();

  const classes = useTableStyles();

  const [dialogProps, setDialogProps] = useState({
    open: false,
    title: "",
    content: "",
    onConfirm: null,
  });

  const token = useApiToken();
  const queryClient = useQueryClient();

  function createNewModel(dynamicArgValues) {
    let defaults = { ...pageData.Defaults };

    // Add custom_data only if it doesn't already exist
    if (defaults.custom_data === undefined) {
      defaults.custom_data = {};
    }

    // Recursive function to handle defaults, including those in children
    function handleDefaults(columns) {
      Object.entries(columns).forEach(([key, column]) => {
        if (column.type === "boolean") {
          if (key === "active") {
            defaults[key] = true;
          } else if (column.default) {
            defaults[key] = column.default;
          } else {
            defaults[key] = false;
          }
        } else if (column.default) {
          defaults[key] = column.default;
        }
        // If column has children, recursively handle their defaults
        if (column.children && typeof column.children === "object") {
          handleDefaults(column.children);
        }
      });
    }

    // Merge defaults from pageData.Columns
    if (pageData.Columns && typeof pageData.Columns === "object") {
      handleDefaults(pageData.Columns);
    }

    // Handle dynamic arguments
    let argValues = dynamicArgValues.reduce((acc, arg) => {
      if (arg === "sandbox_id" && sandbox?.sandboxId) {
        acc[arg] = sandbox.sandboxId;
      } else {
        acc[arg] = "";
      }
      return acc;
    }, {});

    const itemSchema = defaults ? buildItemSchema(formColumns, defaults) : null;

    //handling defaults for setting type and custom endpoint
    const keysToClear = ["setting_type_unique_id", "custom_endpoint_unique_id"];
    keysToClear.forEach((key) => {
      if (itemSchema.hasOwnProperty(key)) {
        itemSchema[key] = null;
      }
    });

    return { ...itemSchema, ...argValues };
  }

  const prevPageDataRef = useRef();

  useEffect(() => {
    if (pageData) {
      if (pageData && prevPageDataRef.current !== pageData.Name) {
        setSelectedItem();
        setRowSelectionModel();
        setShowDetail(false);
        prevPageDataRef.current = pageData.Name;

        setInitialState(null);
        setColumns(null);

        const createEndpoint = pageData.Endpoints.find((e) => e.method === "post");
        setNewModelArgs(createEndpoint ? extractIdsFromPath(createEndpoint.path) : []);

        const dataGridColumns = createOrderedDataGridColumns(
          pageData.Columns,
          visibleColumnData,
          pageData?.RowKey,
          pageData.DynamicSelectFields,
          pageData.HiddenColumns,
          pageData.SortableOptions,
          customDefaultId,
        );

        const columnVisibilityModel = createColumnVisibilityModel(pageData.Columns, visibleColumnData);

        const defaultInitialState = {
          pagination: { paginationModel },
          columns: { columnVisibilityModel },
          sorting: { sortModel: sortModel },
        };

        setInitialState(defaultInitialState);
        setColumns(dataGridColumns);
        //setForceRemountKey(Date.now());
      }
    }
  }, [pageData, visibleColumnData, sandbox]);

  useEffect(() => {
    if (!columns) return;
    setPersistentErrorMsg();

    let newFormColumns;

    const isFirefox = navigator.userAgent.includes("Firefox");

    if (filterValue && pageData.FormOnly) {
      newFormColumns = columns
        .filter((field) => {
          if (filterValue === "Metadata") {
            return field.type === "metadata";
          } else if (filterValue === "general") {
            return field.type !== "parent" && field.type !== "metadata" && !field.nullify && !field.alwaysHidden;
          } else {
            return (
              (!filterValue || filterValue === "all" || field.field === filterValue) &&
              !field.nullify &&
              !field.alwaysHidden
            );
          }
        })
        .sort((a, b) => (a.type === "metadata" && b.type !== "metadata" ? -1 : 1))
        .map((field) => field);
    } else {
      newFormColumns = [...columns].sort((a, b) => (a.type === "metadata" && b.type !== "metadata" ? -1 : 1));
    }

    if (isFirefox) {
      newFormColumns.reverse();
    }

    setFormColumns(newFormColumns);
  }, [filterValue, pageData?.FormOnly, columns, location]);

  // For troubleshooting these heavily use apiUtils.js
  const createMutation = useCustomMutation(queryClient, token, pageData, "create", handleTableData, {
    onSuccessCallback: (data) => handleSuccess("Create", data),
    onError: (error) => handleError(error, "Create"),
    onErrorCallback: (error) => handleErrorCallback(error, "Create"),
  });

  // For troubleshooting these heavily use apiUtils.js
  const updateMutation = useCustomMutation(queryClient, token, pageData, "update", handleTableData, {
    onSuccessCallback: (data) => handleSuccess("Update", data),
    onError: (error) => handleError(error, "Update"),
    onErrorCallback: (error) => handleErrorCallback(error, "Update"),
  });

  // For troubleshooting these heavily use apiUtils.js
  const deleteMutation = useCustomMutation(queryClient, token, pageData, "delete", handleTableData, {
    onSuccessCallback: (data) => handleSuccess("Delete", data),
    onError: (error) => handleError(error, "Delete"),
    onErrorCallback: (error) => handleErrorCallback(error, "Delete"),
  });

  const refetchDataGridData = async () => {
    await queryClient.invalidateQueries(pageData.UniqueFetchKey);
  };

  const handleSuccess = async (operation, data) => {
    const detail = data?.[friendlyNameKey ?? rowKey] ?? "item.";
    setNotificationProps({
      severity: "success",
      message: `Changes saved to ${detailTitleOverride ? detailTitleOverride(detail) : detail}`,
    });

    if (operation === "Update" || operation === "Create") {
      const savedItem = {
        ...data,
        ...(Array.isArray(data.data) ? data.data[0] : data),
      };

      setSelectedItem(savedItem);
      setEditDetail(false);
      if (operation === "Create") {
        const schema = customQueryProps?.schema;

        const isValidSchema = schema === "Matchmaking-profile";

        if (customQueryData && isValidSchema) {
          const firstPostSchema = customQueryProps.customQueries.postSchemas[0];
          const firstPostEndpoint = schemaObjects[firstPostSchema]?.endpoints.find((e) => e.method === "post");

          let argValues = newModelArgs.reduce((acc, arg) => {
            if (arg === "sandbox_id" && sandbox?.sandboxId) {
              acc[arg] = sandbox.sandboxId;
            } else {
              acc[arg] = "";
            }
            return acc;
          }, {});

          const combinedData = { ...data, ...argValues };

          try {
            await apiRequest(token, firstPostEndpoint, customQueryData, combinedData || {});
            await refetchDataGridData();
          } catch (error) {
            console.error("Error during second API request:", error);
          }
          return;
        }
        await refetchDataGridData();
        setRowSelectionModel(data[rowKey]);
        retrieveSelectedRows && retrieveSelectedRows(data);
        setNewItemCreated(false);
        setSelectedItem(data);
        toggleShowDetail(true);
        setEditDetail(false);
        setEditedDetails(data);
        scrollToBottom();
      } else {
        toggleShowDetail(true);
      }
    } else {
      setSelectedItem();
      setRowSelectionModel();
      setEditedDetails();
      toggleShowDetail(false);
      setNewItemCreated(false);
    }

    queryClient.invalidateQueries(pageData.UniqueFetchKey);
  };

  const handleError = (error, operation) => {
    if (typeof onErrorCallback === "function") {
      onErrorCallback(error, variables, context);
    } else {
      console.error("Mutation failed:", error);
    }
    editedItem = selectedItem;
    //queryClient.invalidateQueries(`tableData${pageData.base}`);
  };

  const handleErrorCallback = (error, type) => {
    const parsedErrorDetails = parseErrorObject(error);
    console.error(`${type} Mutation failed:`, error);
    setPersistentErrorMsg(parsedErrorDetails);
  };

  const actuallyDelete = async () => {
    setDialogProps((prev) => ({ ...prev, open: false }));

    deleteMutation.mutate({
      data: selectedItem,
      replacements: { sandbox_id: sandbox?.sandboxId },
      extraHeaders: {},
      extraOptions: {},
    });
  };

  const handleDialogCancel = () => {
    setDialogProps((prev) => ({ ...prev, open: false }));
  };

  function getDefaultForType(field, type) {
    const defaultValues = {
      string: "",
      integer: "",
      number: "",
      boolean: false,
      json: {},
      metadata: "",
      parent: {},
    };

    if (field in pageData?.Defaults) {
      if (pageData.Defaults[field] === null) {
        return null;
      }
      return pageData.Defaults[field];
    }

    return defaultValues[type];
  }

  function buildItemSchema(columns, item = {}) {
    return columns.reduce((schema, col) => {
      if (col.type === "parent") {
        // Pass the relevant subset of 'item' for the 'parent' column
        schema[col.field] = buildItemSchema(col.children, item[col.field] ? item[col.field] : item || {});
      } else {
        // Use the existing item value for this column, or get the default
        schema[col.field] = item.hasOwnProperty(col.field) ? item[col.field] : getDefaultForType(col.field, col.type);
      }
      return schema;
    }, {});
  }

  const handleRowClick = (params) => {
    const item = params.row;

    const itemSchema = item ? buildItemSchema(formColumns, item) : null;
    retrieveSelectedRows && retrieveSelectedRows(itemSchema);
    setNewItemCreated(false);
    setSelectedItem(itemSchema);
    toggleShowDetail(true);
    setEditDetail(false);
    setEditedDetails(itemSchema);
    // setForceRemountKey(Date.now());
    scrollToBottom();
  };

  const handleDelete = async () => {
    const snackDisplayId = selectedItem[friendlyNameKey]
      ? `\n\n${selectedItem[friendlyNameKey]}\n${selectedItem[rowKey]}\n\n`
      : detailTitleOverride
        ? detailTitleOverride(selectedItem)
        : `\n\n${selectedItem[rowKey]}\n\n`;

    if (!dialogProps.open) {
      setDialogProps({
        open: true,
        title: "Confirm Deletion",
        content: `Are you sure you want to delete? ${snackDisplayId} This action cannot be undone.`,
        onConfirm: () => actuallyDelete(),
        onCancel: () => handleDialogCancel(),
      });
    }
  };

  function handleEdit() {
    setEditDetail(true);
    setNewItemCreated(false);
    setEditedDetails(selectedItem);
    onEdit && onEdit();
  }

  function removeLegacyProperties(obj) {
    if (obj === null || obj === undefined) {
      return obj;
    }

    return Object.keys(obj).reduce((accumulator, key) => {
      if (!key.includes("legacy")) {
        if (Array.isArray(obj[key])) {
          accumulator[key] = obj[key].map((item) => {
            if (typeof item === "object" && item !== null) {
              return removeLegacyProperties(item);
            }
            return item;
          });
        } else {
          accumulator[key] = obj[key];
        }
      }
      return accumulator;
    }, {});
  }

  function handleCopy() {
    const newItem = removeLegacyProperties(selectedItem);
    setRowSelectionModel([]);
    setNewItemCreated(true);
    setSelectedItem(newItem);
    setEditedDetails(newItem);
    setEditDetail(true);

    retrieveSelectedRows && retrieveNewItemAsSelectedRow && retrieveSelectedRows(null, /*isEdit*/ true);

    toggleShowDetail(true);
  }

  const setPersistentErrorMsg = (details) => {
    if (details && details.length > 0) {
      const errorMessages = details
        .map((detail) => {
          handleValidationError(detail.field, true, detail.message);
          return `Field: ${detail.field} Error: ${detail.message}`;
        })
        .join("\n");
      setErrorMsg(errorMessages);
      setNotificationProps({
        severity: "error",
        message: "Error Saving",
      });
    } else {
      setErrorMsg("");
      setFieldErrors({});
    }
  };

  const toggleShowDetail = (state) => {
    setShowDetail(state);
    setPersistentErrorMsg();
  };

  const handleCancel = () => {
    setEditedDetails(selectedItem);
    setPersistentErrorMsg();
    if (newItemCreated) {
      toggleShowDetail(false);
    }
    setEditableRows();
    setEditDetail(false);

    onCancel && onCancel(newItemCreated);
    setResetFlag(Date.now());
    // setForceRemountKey(Date.now());
  };

  const handleAddNew = () => {
    //setForceRemountKey(Date.now());
    setRowSelectionModel([]);
    setNewItemCreated(true);
    let newItem = createNewModel(newModelArgs);
    setSelectedItem(newItem);
    setEditedDetails(newItem);
    setEditDetail(true);

    retrieveSelectedRows && retrieveNewItemAsSelectedRow && retrieveSelectedRows(null, /*isEdit*/ true);

    toggleShowDetail(true);
  };

  const handleValidationError = (fieldName, isError, helperText) => {
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: { isError, helperText },
    }));
  };

  async function createMatchmakingTemplateGroup(apiRequest, token, postEndpoint, sandbox, name) {
    let queryData;
    if (!name) {
      queryData = { name: uuid() }; // Generate a unique UUID for the name
    } else {
      queryData = { name: name };
    }

    const initialResponse = await apiRequest(token, postEndpoint, { sandbox_id: sandbox?.sandboxId }, queryData);

    if (initialResponse && initialResponse.match_making_template_group_id) {
      return initialResponse.match_making_template_group_id;
    } else {
      console.log("match_making_template_group_id not found in the response.");
      return null;
    }
  }

  const actuallySave = async (newRows) => {
    setPersistentErrorMsg();
    //for handling (specifically GameConfig) nested objects
    const apiPayload = restructureObject(editedDetails);
    //generates requiredKeys
    const requiredKeys = collectRequiredKeys(pageData?.Columns, new Set(), pageData?.NullableParents);
    //Recursively removes keys that are found in DoNotSendKeys and handle NullableChildren in pageConfig unless they are required keys
    normalizeAndRemoveKeys(apiPayload, pageData?.NullableChildren, pageData?.DoNotSendKeys, requiredKeys);
    //Ensures JSON object is ready to be sent to the API
    prepareJsonForTransport(apiPayload, columns);
    //Ensures that Dynamic Select Fields show the correct format for API: i.e. ["loot_id: 1234"] or ["1234"]
    transformObjectProperties(apiPayload, pageData.DynamicSelectFields);
    // Nulls Parents if Children are all Nulls
    updateNullableParentsInPayload(apiPayload, pageData?.NullableParents);

    if (pageData?.Name === "Queue") {
      const postEndpoint = schemaObjects["Matchmaking-template-group"].endpoints.find((e) => e.method === "post");

      if (!postEndpoint) {
        console.log("No POST endpoints defined in 'schemaObjects'. Proceeding with default logic.");
        return;
      }

      if (!apiPayload.match_making_template_group_id) {
        try {
          const match_making_template_group_id = await createMatchmakingTemplateGroup(
            apiRequest,
            token,
            postEndpoint,
            sandbox,
            apiPayload.matchmaking_template_group_name,
          );
          if (match_making_template_group_id) {
            apiPayload.match_making_template_group_id = match_making_template_group_id;
          }
        } catch (error) {
          console.error("Error during matchmaking ruleset creation:", error);
        }
      }
    }

    if (newItemCreated) {
      createMutation.mutate({
        data: apiPayload,
        replacements: { sandbox_id: sandbox?.sandboxId },
        extraHeaders: {},
        extraOptions: {},
      });
    } else {
      updateMutation.mutate({
        data: apiPayload,
        replacements: { sandbox_id: sandbox?.sandboxId },
        extraHeaders: {},
        extraOptions: {},
      });
    }
  };

  useEffect(() => {
    const lastTag = getLastTagFromPath();
    setFilterValueBasedOnTag(lastTag);

    if (!pageData?.FormOnly || tableData?.length === 0 || rowSelectionModel?.length > 0) return;

    if (filterValue) {
      initializeSelection();
    }
  }, [pageData?.FormOnly, tableData, rowKey, rowSelectionModel, setSelectedItem, filterValue, location]);

  const getLastTagFromPath = () => {
    const path = window.location.pathname;
    const segments = path.split("/");
    return segments[segments.length - 1];
  };

  const setFilterValueBasedOnTag = (tag) => {
    setFilterValue(tag);
  };

  const initializeSelection = () => {
    const firstItem = tableData[0];

    setSelectedItem(firstItem);
    setEditedDetails(firstItem);
    setRowSelectionModel([firstItem]);
    toggleShowDetail(true);
  };

  const showDetailForm = () => {
    const rowIsSelectedOrIsNewItem = newItemCreated || rowSelectionModel?.length > 0;
    return showDetail && rowIsSelectedOrIsNewItem;
  };

  const renderField = (field, parentKeys = []) => {
    if (!field || pageData?.HiddenFormFields?.includes(field?.field)) {
      return;
    }

    if (newItemCreated && field.field === "event_name") {
      field.type = "string";
    } else if (field.field === "event_name") {
      field.type = "metadata";
    }

    if (field.type === "metadata" && newItemCreated) {
      return;
    }

    if (field?.field === "match_making_ruleset_id" && newItemCreated) {
      return;
    }

    //UGH
    if (field?.field === "match_making_template_group_id" && newItemCreated) {
      return;
    }

    const isInCustomColumns = pageData?.CustomColumns?.some((customColumn) => {
      return Object.keys(customColumn).includes(field.field);
    });

    if (isInCustomColumns && newItemCreated !== true) {
      return;
    }

    const fieldKey = parentKeys.length > 0 ? `${parentKeys.join(".")}.${field.field}` : field.field;

    const hasError = fieldErrors[fieldKey]?.isError;

    const uglyBorders = new Set([
      "NintendoConfig",
      "NintendoEnv",
      "TwitchConfig",
      "AnonConfig",
      "BasicConfig",
      "SocialConfig",
      "PlaystationConfig",
      "EpicOnlineServicesConfig",
      "SteamConfig",
      "AmazonConfig",
      "GoogleConfig",
      "AppleConfig",
      "XboxConfig",
      "VivoxConfig",
      "LegacyConfig",
    ]);

    if (field.type === "parent") {
      return (
        <div
          key={fieldKey + field.type}
          style={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            width: "100%",
            marginBottom: "10px",
          }}
        >
          <RHFieldWrapper
            label={parentKeys.length > 0 ? `${field.headerName} (${field.field})` : field.headerName}
            inactive={!editDetail ? !newItemCreated : undefined}
            isParent={true}
            uglyBorders={uglyBorders.has(field.headerName)}
            checkbox={false}
            checked={false}
            handleChange={undefined} // Correctly passing the handler
          >
            {field.children.map((child) => renderField(child, [...parentKeys, field.field]))}
          </RHFieldWrapper>
        </div>
      );
    } else {
      return (
        <div
          key={fieldKey + field.type}
          style={{
            ...getGeneralFormStylesSx(field, false, pageData),
            display: field.type === "customdata" ? "block" : "inline-block",
            marginRight: "10px",
          }}
        >
          <FormField
            // key={forceRemountKey}
            resetFlag={resetFlag}
            selectedItem={selectedItem}
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            editDetail={editDetail}
            field={field}
            rowKey={rowKey}
            parentKey={parentKeys.length === 1 ? parentKeys[0] : parentKeys.join(".")}
            newItemCreated={newItemCreated}
            description={field.info}
            sandbox={sandbox}
            handleValidationError={handleValidationError}
            hasError={hasError}
            pageData={pageData}
          />
        </div>
      );
    }
  };
  return (
    <>
      <Box className={classes.rootConfigPage} sx={{}}>
        {!pageData?.FormOnly && pageData?.Name != "AdminDashboardData" ? (
          <ToolBar
            handleAddNew={!pageData?.FormOnly && hasEditPermission ? handleAddNew : undefined}
            handleCancel={handleCancel}
            handleDelete={!pageData?.FormOnly && hasEditPermission ? handleDelete : undefined}
            handleCopy={shouldCopy && hasEditPermission ? handleCopy : undefined}
            handleEdit={handleEdit}
            handleSave={actuallySave}
            selectedItem={rowSelectionModel && rowSelectionModel.length > 0 ? rowSelectionModel : false}
            className={classes}
            hideHeader={false}
            selectedInlineRows={selectedInlineRows}
            activelyEditing={editableRows ? true : false}
            onImport={onImport}
            onExport={onExport}
            onDocs={onDocs}
            onClearSettings={onClearSettings}
            friendlyName={!friendlyName ? (!friendlyNameKey ? columns[0].headerName : undefined) : friendlyName}
            title={friendlyName}
            hideSearchBoxTitle={hideSearchBoxTitle}
            searchOrCreateText={searchOrCreateText}
            loadDefaults={loadDefaults}
            onFilterChangeHandler={onFilterChangeHandler}
            enableSearchByName={enableSearchByName}
            onHistory={onHistory}
            historyView={historyView}
            enableSmartSearch={enableSmartSearch}
          />
        ) : (
          <></>
        )}
        {tableData && columns && initialState && !pageData?.FormOnly && (
          <Box
            className={classes.tableHeader}
            sx={{
              bgcolor: "background.paper",
              border: "none",
              color: "#FFFFFF95",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
              borderBottomLeftRadius: "4px",
              borderBottomRightRadius: "4px",
            }}
          >
            <DataGrid
              {...props}
              apiRef={gridApiRef}
              // key={forceRemountKey}
              className={classes.table}
              initialState={initialState}
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
              getRowId={(row) => row[rowKey]}
              rowHeight={32}
              onRowClick={disableRowClick ? () => {} : handleRowClick}
              rows={tableData}
              columns={columns}
              pagination
              paginationMode={"server"}
              onPaginationModelChange={onPaginationChanged}
              sortingMode={pageData.SortableOptions.length > 0 ? "server" : "client"}
              onSortModelChange={onSortModelChanged}
              rowCount={rowCount}
              autoHeight
              editMode="row"
              onRowSelectionModelChange={(items) => {
                setSelectedInlineRows(items);
                setRowSelectionModel(items);
                onSelectedRowsChanged && onSelectedRowsChanged(items);
              }}
              rowSelectionModel={rowSelectionModel}
              sx={{
                bgcolor: "background.paper",
                border: "none",
                minWidth: 0,
                p: 1,
                "& .MuiDataGrid-cell": {
                  //fontSize: ".9rem",
                  fontWeight: "light",
                  border: 0,
                },
                "& .MuiDataGrid-columnHeader": {
                  fontWeight: "bold",
                  paddingRight: "40px",
                },
                "& .MuiDataGrid-row:nth-of-type(odd)": {
                  backgroundColor: "rgba(245, 245, 245, .025)", // Light background for odd rows
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#9d69ff29", // Highlight row on hover
                },
                "& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover": {
                  backgroundColor: "#9d69ff50", // Custom background for selected rows
                  color: "#FFF",
                },
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  justifyContent: "space-between",
                  borderBottomLeftRadius: "4px",
                  borderBottomRightRadius: "4px",
                },
                "& .MuiDataGrid-menuIconButton": {
                  visibility: "visible !important",
                  position: "absolute",
                  right: "5px",
                  top: "50%",
                  transform: "translateY(-50%)",
                },
                // your other styles...
                "& .MuiDataGrid-root": {
                  "--unstable_DataGrid-radius": "0px",
                },
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  // your existing styles
                  "--unstable_DataGrid-radius": "4px",
                },
              }}
            />
          </Box>
        )}

        {showDetailForm() && (
          <Box className={classes.tablesDetailContainer}>
            <Box className={classes.tableDetailView}>
              <RHAccordion
                summary={
                  <>
                    <Typography
                      onClick={(e) => {
                        e.stopPropagation();
                        // Logic to copy the value after "ID"
                        const idValue = selectedItem[friendlyNameKey || rowKey];
                        navigator.clipboard.writeText(idValue).then(() => {
                          console.log("Value copied to clipboard");
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {pageData?.FormOnly
                        ? `${capitalizeFirstLetter(filterValue)} ${friendlyName}`
                        : rowKey && selectedItem && selectedItem.hasOwnProperty(rowKey)
                          ? `${
                              friendlyName ?? capitalizeFirstLetter(friendlyName)
                            } ID: ${selectedItem[friendlyNameKey || rowKey]}`
                          : `New ${friendlyName ?? capitalizeFirstLetter(friendlyName)}`}
                    </Typography>
                  </>
                }
              >
                {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : <></>}
                <AccordionDetails sx={{ bgcolor: "background.paper" }}>
                  {historyView ? (
                    <HistoryView
                      selectedSchemaObject={selectedSchemaObject}
                      version_id={selectedItem.version_id}
                      transaction_id={selectedItem.transaction_id}
                      filter={historyFilter}
                    />
                  ) : (
                    <Box className={classes.tableDetailViewContents}>
                      <Box
                        className={classes.tableDetailViewContentsButtonBar}
                        sx={{
                          position: "sticky",
                          top: 105,
                          // backgroundColor: "#1e1e1e",
                          padding: "10px",
                          zIndex: "8",
                        }}
                      >
                        {editDetail ? (
                          <>
                            <Tooltip title="Exit edit mode">
                              <Button
                                onClick={() => {
                                  handleCancel();
                                }}
                                variant="outlined"
                                sx={{
                                  backgroundColor: "#1e1e1e",
                                }}
                              >
                                Cancel
                              </Button>
                            </Tooltip>
                            <Tooltip title={`Save ${friendlyName.toUpperCase()} Table`}>
                              <Button onClick={actuallySave} variant="contained">
                                Save
                              </Button>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            {!isEditModeDisabled && hasEditPermission ? (
                              <>
                                <Tooltip title="Enter edit mode">
                                  <Button
                                    onClick={handleEdit}
                                    variant="outlined"
                                    sx={{
                                      backgroundColor: "#1e1e1e",
                                    }}
                                    disabled={isRowEditable && !isRowEditable(editedDetails)}
                                  >
                                    Edit
                                  </Button>
                                </Tooltip>
                                {additionalEditButtons && additionalEditButtons.map((btn) => btn())}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </Box>
                      {/* Dynamically generates form fields based on column definitions from formColumns and renders them. */}
                      <Box className={classes.tableDetailForm}>{formColumns.map((field, i) => renderField(field))}</Box>
                    </Box>
                  )}
                </AccordionDetails>
              </RHAccordion>
            </Box>
          </Box>
        )}
        <RHConfirmDialog
          open={dialogProps.open}
          onConfirm={dialogProps.onConfirm}
          onCancel={dialogProps.onCancel}
          title={dialogProps.title}
          content={dialogProps.content}
        />
      </Box>
    </>
  );
}
