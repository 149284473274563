import React, { useState, useContext, useEffect, useMemo, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import AccountContext from "../contexts/AccountContext";
import { useSandboxStyles } from "./sandbox/styles";
import RHDynamicLoadingMask from "../components/common/RHDynamicLoadingMask";
import * as helpers from "../common/helpers";
import { apiRequest, useApiToken } from "../common/apiUtils";
import RHEditableTable_v2 from "../components/common/RHEditableTable_v2/index";
import { useDataContext } from "contexts/DataContext";
import pageConfig from "../common/pageConfig.json";
import Notification from "../components/common/Notification";

import {
  createResource,
  processPartitions,
  loadCrossplayPartitionDefaults,
  loadMatchMakingStrideDefaults,
  loadSessionTemplateDefaults,
  loadRuleSetsDefaults,
  buildPartitionData,
} from "../common/defaultsHelpers";

export default function RHDynamicPage(props) {
  const { dataContext } = useDataContext();

  const { manualUpdateSchemaObjects } = useDataContext();
  const {
    page,
    subTableOptions,
    body,
    customDefaultId,
    pageSubtable,
    enableSmartSearch: propEnableSmartSearch = false, // New prop with default value
    pageIdentifier, // New prop to identify the page
  } = props;
  const classes = useSandboxStyles();

  const { currentStateData, permissionData } = useContext(AccountContext);
  const [selectedTableRow, setSelectedTableRow] = useState();
  const [hasEditPermission, setHasEditPermission] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    message: "",
    severity: "info",
  });

  const [forceRemountKey, setForceRemountKey] = useState(Date.now());
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [recordCounts, setRecordCounts] = useState({
    add: 0,
    delete: 0,
    update: 0,
  });
  const [uploadedRows, setUploadedRows] = useState([]); // Add this state
  const [rowCount, setRowCount] = useState(0);
  const [filterModel, setFilterModel] = useState({});
  const [paginationModel, setPaginationModel] = useState({});
  const [sortModel, setSortModel] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [cachedData, setCachedData] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [customRowKeyValues, setCustomRowKeyValues] = useState([]);
  const [customQueryData, setCustomQueryData] = useState(null);
  const [loadingDefaults, setLoadingDefaults] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const schemaObjects = dataContext.schemaObjects;
  const token = useApiToken();
  const replacements = {
    sandbox_id: currentStateData?.sandbox?.sandboxId,
    environment_id: currentStateData?.environment?.environmentId,
  };

  const [userHasViewPermission, setUserHasViewPermission] = useState(true);
  const [userHasEditPermission, setUserHasEditPermission] = useState(true);

  const location = useLocation();

  const accessDeniedText = "Access Denied: User lacks required view permissions.";

  function checkUserPermissions(contextType = "sandbox") {
    const permissions = {
      sandbox: {
        edit: "sandbox:config:edit",
        view: "sandbox:config:view",
      },
      environment: {
        edit: "environment:config:edit",
        view: "environment:config:view",
      },
    };

    const { edit: editPermission, view: viewPermission } = permissions[contextType];

    // Helper function to determine if the user has a specific permission
    const hasPermission = (permissionToCheck) => {
      // Early return if data not ready
      if (!permissionData?.permissions || !currentStateData?.[contextType]?.permissions) return undefined;

      const isGlobalAdmin = permissionData?.isGlobalAdmin;
      if (isGlobalAdmin) return true; // Early return for global admins

      const hasContextPermission = currentStateData[contextType]?.permissions?.includes(permissionToCheck);
      const hasDetailedPermission = permissionData?.permissions?.some(
        (permission) =>
          permission.permission_id === permissionToCheck &&
          [
            currentStateData[`${contextType}_id`],
            currentStateData.product?.productId,
            currentStateData.org?.orgId,
          ].includes(permission[`${contextType}_id`] || permission.product_id || permission.org_id),
      );

      return hasContextPermission || hasDetailedPermission;
    };

    // Check for edit permission first since it implies view permission
    const userHasEdit = hasPermission(editPermission);
    const userHasView = userHasEdit || hasPermission(viewPermission);

    // Ensure data is ready before logging warnings
    if (permissionData?.permissions && currentStateData?.[contextType]?.permissions) {
      if (userHasView === false) {
        console.warn(accessDeniedText);
        console.warn(
          `Missing Required ${
            contextType.charAt(0).toUpperCase() + contextType.slice(1)
          } View Permission: ${viewPermission}`,
        );
      }

      if (userHasEdit === false) {
        console.warn(
          `Missing Required ${
            contextType.charAt(0).toUpperCase() + contextType.slice(1)
          } Edit Permission: ${editPermission}`,
        );
      }
    }

    return {
      userHasViewPermission: userHasView,
      userHasEditPermission: userHasEdit,
    };
  }

  // Effect hook to check permissions when dependencies change
  useEffect(() => {
    if (permissionData?.permissions && currentStateData?.sandbox?.permissions) {
      const permissions = checkUserPermissions();
      setUserHasViewPermission(permissions.userHasViewPermission);
      setUserHasEditPermission(permissions.userHasEditPermission);
    }
  }, [selectedPage, schemaObjects, sortModel, paginationModel, permissionData, currentStateData]);

  // Memoize the currentPageData based on dependencies to avoid unnecessary recalculations
  const currentPageData = useMemo(
    () => schemaObjects[selectedPage],
    [selectedPage, schemaObjects, sortModel, paginationModel, userHasViewPermission, userHasEditPermission],
  );

  const pageCheck = selectedPage === processPageSelection(props.currentStateData, props.page);

  useEffect(() => {
    manualUpdateSchemaObjects();
    const newSelectedPage = processPageSelection(props.currentStateData, props.page);
    setSelectedPage(newSelectedPage);
    setFilterModel("");
    setPageNumber(0);
  }, [props.currentStateData?.apiId, props.page]);

  function appendQuickFilters(queryParams, filterText) {
    if (filterText !== "") {
      const searchParam = currentPageData?.smartSearch ? "smart_search" : "name";
      queryParams.append(searchParam, filterText);
    }

    const url = new URL(window.location.href);

    // Check if the 'id' query parameter is present
    const id = url.searchParams.get("id");

    if (id) {
      console.log("ID present:", id);
      queryParams.append("item_ids", [id]);
    }
  }

  function appendSorting(queryParams, defaultSortModel, isExternalKeyCampaign) {
    const activeSortModel = defaultSortModel;

    const finalSortModel = isExternalKeyCampaign
      ? activeSortModel.filter((sortItem) => sortItem.field !== "last_modified_timestamp")
      : activeSortModel;

    finalSortModel.forEach((sortItem) => {
      queryParams.append("sort_by", sortItem.field);
      queryParams.append("sort_order", sortItem.sort);
    });
  }

  function appendPagination(queryParams, defaultPaginationModel, pageNumber) {
    const { pageSize, page } = defaultPaginationModel;

    queryParams.append("page_size", pageSize);

    queryParams.append("cursor", pageNumber);
  }

  const shouldFetch = !!(
    token &&
    (["AdminDashboardData", "DefaultVoipConfigResponse", "files"].includes(currentPageData?.base)
      ? true
      : replacements) &&
    (["AdminDashboardData", "DefaultVoipConfigResponse", "files"].includes(currentPageData?.base)
      ? true
      : currentStateData?.sandbox?.sandboxId) &&
    currentPageData &&
    pageCheck &&
    userHasViewPermission
  );

  const fetchTableData = useCallback(
    async (filterModel, sortModel, paginationModel, pageNumber = 0) => {
      setFetchError(false);
      let queryParams = new URLSearchParams();

      let fetchAllEndpoint = currentPageData?.fetchAllEndpoint;
      if (currentPageData?.base === "TransactionData") {
        fetchAllEndpoint = {
          path: `/v1/history/sandbox/{sandbox_id}/${currentPageData?.version}`,
          method: "get",
        };
      }

      const isExternalKeyCampaign = currentPageData?.base === "ExternalKeyCampaign";

      // Apply handlers
      appendQuickFilters(queryParams, filterModel);
      appendSorting(queryParams, currentPageData?.sortModel, isExternalKeyCampaign);
      appendPagination(queryParams, currentPageData?.paginationModel, pageNumber);

      // Handle additional subTableOptions
      if (subTableOptions?.queryParams && typeof subTableOptions.queryParams === "object") {
        Object.entries(subTableOptions.queryParams).forEach(([key, value]) => {
          queryParams.set(key, value);
        });
      }

      if (["Matchmaking-profile", "Matchmaking-template"].includes(pageSubtable?.schema)) {
        // Shortened schema check for readability
        const schema = pageSubtable?.schema;

        // Assuming the first schema in getSchemas array determines the endpoint and array names
        const firstGetSchema = pageSubtable?.customQueries?.getSchemas?.[0];
        const schemaObject = schemaObjects[firstGetSchema];
        const firstGetEndpoint = schemaObject?.fetchAllEndpoint;
        const dataArrayName = schemaObject?.tableDataArrayName;
        const rowKeyName =
          schema === "Matchmaking-profile" ? schemaObjects[pageSubtable?.schema]?.rowKey : pageSubtable?.filterKey;

        // Prepare queryData, set it if customDefaultIdValue exists
        const customDefaultIdValue = props.body ? props.body[customDefaultId] : undefined;
        const queryData = customDefaultIdValue ? { [customDefaultId]: customDefaultIdValue } : {};
        setCustomQueryData(queryData);

        if (firstGetEndpoint) {
          try {
            const initialResponse = await apiRequest(
              token,
              firstGetEndpoint,
              replacements,
              queryData,
              {},
              { queryParams: queryParams.toString() },
            );

            if (dataArrayName && Array.isArray(initialResponse[dataArrayName])) {
              const newRowKeyValues = initialResponse[dataArrayName].map((item) => item[rowKeyName]);
              setCustomRowKeyValues(newRowKeyValues); // Update state with new values
            } else {
              console.log("Data array name not found or invalid in the response.");
            }
          } catch (error) {
            console.error("Error during initial API request:", error);
          }
        } else {
          console.log("No GET endpoints defined in 'customQueries'. Proceeding with default logic.");
        }
      }
      queryParams.append("expand", "*");

      let extraOptions = { queryParams: queryParams.toString() };
      return apiRequest(token, fetchAllEndpoint, replacements, props.body ? props.body : {}, {}, extraOptions);
    },
    [token, subTableOptions, replacements, currentPageData], // dependencies array for useCallback
  );

  const uniqueFetchKey = `tableData${schemaObjects[selectedPage]?.base}_${currentStateData?.sandbox?.sandboxId}`;

  const { data, isLoading, isError, error, refetch, isFetching } = useQuery({
    queryFn: () => fetchTableData(filterModel, sortModel, paginationModel, pageNumber),
    queryKey: [uniqueFetchKey, subTableOptions, filterModel, sortModel, paginationModel, pageNumber],
    enabled: shouldFetch,
    retries: 3,
    staleTime: 10000,
    retryDelay: (retryCount) => (retryCount <= 3 ? 1000 : 3000),
    retry: (failureCount, error) => {
      if (failureCount === 1) {
        console.log("Attempting to self-heal...");
        handleClearSettings();
        return true;
      } else if (failureCount > 3) {
        return false;
      }
      return true;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: (err) => {
      // This will be called after the final retry attempt fails
      console.warn(
        `Reached Max Attempt of Retries for fetching ${currentPageData?.friendlyName}, Please refresh the page to try again.`,
      );
      setFetchError(true);
    },
  });

  function generateQueryOptions(selectedRow, rowKey, filterKey) {
    if (rowKey !== undefined && selectedRow?.[rowKey] !== undefined) {
      return {
        queryParams: {
          [filterKey]: selectedRow[rowKey],
        },
      };
    }
    return {};
  }

  function processPageSelection(currentStateData, defaultPage) {
    if (defaultPage) {
      return helpers.capitalizeFirstLetters(defaultPage);
    } else if (typeof currentStateData?.apiId === "string") {
      return helpers.capitalizeFirstLetters(currentStateData.apiId);
    }
    return "";
  }

  //Export Data as CSV
  async function onExport(selectedData) {
    try {
      await helpers.handleCsvExport(
        selectedData,
        token,
        currentPageData?.fetchAllEndpoint,
        currentStateData?.sandbox?.sandboxId,
      );
    } catch (error) {
      console.error("Error Exporting CSV:", error.message);
    }
  }

  //Import Data from CSV
  async function onImport(event) {
    try {
      const { rows, recordCounts } = await helpers.handleCsvImport(event.target.files[0], cachedData, selectedPage);
      setUploadedRows(rows);
      setRecordCounts(recordCounts);
      setConfirmDialogOpen(true);
    } catch (error) {
      console.error("Error Importing CSV:", error.message);
    }
  }

  //Import Status Confirm Dialog Box
  async function handleConfirmImport() {
    setConfirmDialogOpen(false);
    const rowKey = cachedData[selectedPage].RowKey;

    const itemsToDelete = helpers.getItemsToDelete(uploadedRows, cachedData[selectedPage], rowKey);

    try {
      await performImportDeletion(itemsToDelete, rowKey);
      await performImportUpdate(uploadedRows, rowKey);
      await refetch();
      setForceRemountKey(Date.now());
    } catch (error) {
      console.error("Error during import operation:", error);
    }
  }

  function onDocs(event) {
    if (!currentPageData?.docs) {
      return undefined;
    }

    try {
      const url = `https://docs.rallyhere.gg/Developer Portal/${currentPageData.docs}`;
      window.open(url, "_blank", "noopener,noreferrer");
    } catch (error) {
      console.error("Error: ", error.message);
    }
  }

  function onHistory(event) {
    if (!currentPageData?.historyButton) {
      console.log(currentPageData);
      return undefined;
    }

    try {
      const url = location.pathname;
      const basePath = url.substring(0, url.lastIndexOf("/"));
      const lastSegment = url.substring(url.lastIndexOf("/") + 1);

      // Use the pageIdentifier to determine which page we're on
      const historySegment = pageIdentifier ? `${pageIdentifier}-history` : `${lastSegment}-history`;
      const newUrl = `${basePath}/${historySegment}`;

      console.log("Navigating to: ", newUrl);
      window.location.href = newUrl;
    } catch (error) {
      console.error("Error: ", error.message);
    }
  }

  // The start of loading defaults logic $$Saint Needs to be moved to a helper function
  async function createResource(resourceName, payload, token, schemaObjects, currentStateData) {
    const endpoint = schemaObjects[resourceName]?.endpoints.find((e) => e.method === "post");
    if (!endpoint) {
      throw new Error(`Endpoint for ${resourceName} not defined.`);
    }

    const response = await apiRequest(token, endpoint, { sandbox_id: currentStateData?.sandbox?.sandboxId }, payload);

    let resourceId;
    if (resourceName === "Crossplay-partition") {
      resourceId = "crossplay_partition_id";
    } else if (resourceName === "Crossplay-profile") {
      resourceId = "crossplay_profile_id";
    } else if (resourceName === "Crossplay-pool") {
      resourceId = "crossplay_pool_id";
    } else if (resourceName === "Crossplay-pool-tag") {
      resourceId = "crossplay_pool_tag_id";
    } else if (resourceName === "Matchmaking-stride") {
      resourceId = "match_making_stride_id";
    } else if (resourceName === "Matchmaking-stride-entry") {
      resourceId = "match_making_stride_entry_id";
    } else if (resourceName === "Session-template") {
      resourceId = "session_template_id";
    } else if (resourceName === "Platform-session-template") {
      resourceId = "last_modified_timestamp";
    } else {
      throw new Error(`Resource name ${resourceName} is not handled.`);
    }

    return response[resourceId];
  }

  async function processPartitions(partitionData, token, schemaObjects, currentStateData) {
    for (const [partitionName, { profiles }] of Object.entries(partitionData)) {
      const crossplay_partition_id = await createResource(
        "Crossplay-partition",
        { name: partitionName },
        token,
        schemaObjects,
        currentStateData,
      );

      for (const [profileName, { pools }] of Object.entries(profiles)) {
        const crossplay_profile_id = await createResource(
          "Crossplay-profile",
          { name: profileName, crossplay_partition_id },
          token,
          schemaObjects,
          currentStateData,
        );

        for (const [poolName, { tags }] of Object.entries(pools)) {
          const crossplay_pool_id = await createResource(
            "Crossplay-pool",
            { name: poolName, crossplay_profile_id },
            token,
            schemaObjects,
            currentStateData,
          );

          for (const tag of tags) {
            await createResource(
              "Crossplay-pool-tag",
              { tag, crossplay_pool_id },
              token,
              schemaObjects,
              currentStateData,
            );
          }
        }
      }
    }
  }

  async function loadDefaultValues() {
    // see defaultsHelpers.js for the following functions
    setLoadingDefaults(true);
    const pageName = cachedData[selectedPage]?.Name;
    if (!pageName) {
      console.error("cachedData[selectedPage] is not properly defined.");
      return;
    }

    console.log(`Loading Default Values for ${pageName}, please hold`);

    // Adjust the strategy calls to pass required parameters
    const defaultLoadStrategies = {
      CrossplayPartition: async () =>
        loadCrossplayPartitionDefaults(pageName, token, schemaObjects, currentStateData, cachedData, selectedPage),
      MatchMakingStride: async () =>
        loadMatchMakingStrideDefaults(pageName, token, schemaObjects, currentStateData, cachedData, selectedPage),
      SessionTemplate: async () => loadSessionTemplateDefaults(pageName, token, schemaObjects, currentStateData),
      InventoryBucketUseRuleSet: async () =>
        loadRuleSetsDefaults(pageName, token, schemaObjects, currentStateData, cachedData, selectedPage),
    };

    if (defaultLoadStrategies[pageName]) {
      await defaultLoadStrategies[pageName]();
    } else {
      console.log(`No specific default loading strategy for ${pageName}.`);
    }

    setLoadingDefaults(false);
    refetch();
  }

  function findEndpoint(method) {
    let endpoint = cachedData[selectedPage].Endpoints.find((e) => e.method === method);
    return { ...endpoint, method };
  }

  //Import Function
  async function performImportDeletion(items, rowKey) {
    await deleteItems(items, rowKey);
  }

  //Import Function
  async function performImportUpdate(items, rowKey) {
    await createOrUpdateItems(items, rowKey);
  }

  //Import Function
  async function deleteItems(items, rowKey) {
    const delEndpoint = findEndpoint("get");
    delEndpoint.method = "delete";

    for (const obj of items) {
      const itemId = obj[rowKey];
      await apiRequest(token, endpoint, replacements, obj, {}, {}, body).catch((error) => {
        console.error("Error during delete operation for item ID " + itemId + ":", error);
      });
    }
  }

  //Import Function
  async function createOrUpdateItems(items, rowKey) {
    for (const obj of items) {
      delete obj.legacy_item_id;
      const endpoint = findEndpoint(rowKey ? "put" : "post");
      await apiRequest(token, endpoint, replacements, obj, {}, {}, body).catch((error) => {
        console.error("Error during create/update operation:", error);
      });
    }
  }
  const filterKey = cachedData[selectedPage]?.SubTable?.filterKey;
  const rowKey = cachedData[selectedPage]?.RowKey;

  const onFilterChangeHandler = (newTextFilter) => {
    setFilterModel(newTextFilter);
  };

  const onColumnVisibilityModelChangeHandler = (newColumnVisibilityModel) => {
    const pageKey = `${currentPageData.base}State`;
    const visibleColumns = Object.keys(newColumnVisibilityModel).filter((key) => newColumnVisibilityModel[key]);

    helpers.updateTableSettings(pageKey, "visibleColumns", visibleColumns);
    manualUpdateSchemaObjects();
  };

  const onSortModelChangeHandler = (newSortModel) => {
    setSortModel(newSortModel);
    const pageKey = `${currentPageData.base}State`;
    helpers.updateTableSettings(pageKey, "sortModel", newSortModel);
    manualUpdateSchemaObjects();
  };

  const onPaginationChangeHandler = (newPageModel) => {
    const pageSize = Number(newPageModel.pageSize) || 10;
    const page = Number(newPageModel.page) || 0;

    const savedPaginationModel = {
      pageSize: pageSize,
      page: 0,
    };

    setPageNumber(page);

    const pageKey = `${currentPageData.base}State`;

    helpers.updateTableSettings(pageKey, "paginationModel", savedPaginationModel);
    setPaginationModel(savedPaginationModel);
    manualUpdateSchemaObjects();
  };

  const findKeyByBaseValue = (config, baseValue) => {
    const foundEntry = Object.entries(config).find(([key, value]) => value.base === baseValue);

    return foundEntry ? foundEntry[0] : undefined;
  };

  const handleClearSettings = () => {
    if (userHasViewPermission) {
      const localStorageKey = `${currentPageData.base}State`;

      const configKey = findKeyByBaseValue(pageConfig, currentPageData.base);

      if (!configKey) {
        console.error(`No configuration found for base: ${currentPageData.base}`);
        return;
      }

      console.log(`Resetting table settings for: ${localStorageKey} using config: ${configKey}`);

      const defaultSettings = {
        visibleColumns: pageConfig[configKey].visibleColumns,
        sortModel: [],
        paginationModel: { pageSize: 10, page: 0 },
      };

      Object.entries(defaultSettings).forEach(([settingKey, value]) => {
        helpers.updateTableSettings(localStorageKey, settingKey, value);
      });

      setSortModel(defaultSettings.sortModel);
      setPaginationModel(defaultSettings.paginationModel);

      const newForceRemountKey = Date.now();
      manualUpdateSchemaObjects();
      setForceRemountKey(newForceRemountKey);

      setFilterModel("");
    }
  };

  const options = useMemo(() => {
    return generateQueryOptions(selectedTableRow, rowKey, filterKey);
  }, [selectedTableRow, rowKey]);

  const shouldRenderRHDynamicPage = () => {
    if (!cachedData[selectedPage] || !cachedData[selectedPage]?.SubTable || !options) {
      return false;
    }

    const condition =
      (cachedData[selectedPage].SubTable.schema && Object.keys(options).length > 0) ||
      (cachedData[selectedPage].SubTable.schema && !!cachedData[selectedPage].SubTable.skipRowSelection);

    return condition;
  };

  useEffect(() => {
    if (currentStateData) {
      window.scrollTo(0, 0);
    }
  }, [currentStateData]);

  useEffect(() => {
    if (isError && error) {
      setNotificationProps({ message: error.message, severity: "error" });
    }
  }, [isError, error]);

  const clearNotification = () => {
    setNotificationProps({ message: "", severity: "info" });
  };

  function isLoadingOrNoData() {
    return (
      ((isLoading ||
        !cachedData[selectedPage]?.TableData?.[cachedData[selectedPage]?.TableDataArrayName] ||
        !pageCheck ||
        loadingDefaults) &&
        !initialized) ||
      !cachedData[selectedPage] ||
      initialized !== currentPageData?.base
    );
  }

  const loadMessage = fetchError
    ? `Unable to retrieve ${currentPageData?.friendlyName}s`
    : userHasViewPermission
      ? "Retrieving the latest data, please wait..."
      : accessDeniedText;

  const showError = !!(fetchError || !userHasViewPermission);

  const tableData = useMemo(() => {
    return cachedData?.FormOnly
      ? cachedData[selectedPage]?.TableData
      : cachedData[selectedPage]?.TableData?.[cachedData[selectedPage]?.TableDataArrayName];
  }, [cachedData]);

  const visibleColumns = currentPageData?.visibleColumns;
  const sortModelValue = currentPageData?.sortModel;
  const paginationModelValue = currentPageData?.paginationModel;

  // We are driving off of our saved data, however we don't want to keep the page number when coming back to a page
  if (paginationModelValue) {
    paginationModelValue.page = pageNumber;
  }

  // if (pageCheck) {
  //   console.log("RENDER VISIBLE COLUMNS: ", visibleColumns);
  //   console.log("RENDER SORTING: ", sortModelValue[0]);
  //   console.log("RENDER PAGINATION:", paginationModelValue);
  // }

  useEffect(() => {
    if (!data || !currentPageData) return;
    let fetchedData = data;
    if (fetchedData?.page_meta === undefined && paginationModel?.pageSize !== 50) {
      const savedPaginationModel = {
        pageSize: 50,
        page: 0,
      };

      setPageNumber(0);

      const pageKey = `${currentPageData.base}State`;

      helpers.updateTableSettings(pageKey, "paginationModel", savedPaginationModel);
      setPaginationModel(savedPaginationModel);
      manualUpdateSchemaObjects();
    }

    const keyName = currentPageData?.tableDataArrayName;

    const schema = pageSubtable?.schema;
    const rowKeyName = schema === "Matchmaking-template" ? pageSubtable?.filterKey : currentPageData?.rowKey;

    if (["Matchmaking-profile", "Matchmaking-template"].includes(schema) && Array.isArray(fetchedData[keyName])) {
      // Filter out items that do not have rowKey values in customRowKeyValues
      fetchedData[keyName] = fetchedData[keyName].filter((item) => customRowKeyValues.includes(item[rowKeyName]));
    }

    let transformedFetchedData;

    if (currentPageData?.formOnly) {
      const keyName = currentPageData?.tableDataArrayName;
      transformedFetchedData = {
        [keyName]: [fetchedData],
      };
      fetchedData = transformedFetchedData;
    } else if (!fetchedData[keyName]) {
      fetchedData = {
        [keyName]: Array.isArray(fetchedData) ? fetchedData : [fetchedData],
      };
    }

    const rowCount =
      fetchedData?.page_meta?.total || (Array.isArray(fetchedData[keyName]) ? fetchedData[keyName].length : 1);
    setRowCount(rowCount);

    let customDefault = {};
    if (customDefaultId && body[customDefaultId] !== undefined) {
      customDefault = { [customDefaultId]: body[customDefaultId] };
    }

    if (currentPageData && Array.isArray(currentPageData.customColumns)) {
      currentPageData.customColumns.forEach((customColumn) => {
        // Use the keys of customColumn to add properties to currentPageData.columns
        Object.keys(customColumn).forEach((key) => {
          currentPageData.columns[key] = customColumn[key];
        });
      });
    }

    setCachedData((prev) => ({
      ...prev,
      [selectedPage]: {
        Name: currentPageData?.base,
        TableData: fetchedData,
        TableDataArrayName: currentPageData?.tableDataArrayName,
        PostDataArrayName: currentPageData?.postDataArrayName,
        RowKey: currentPageData?.rowKey,
        Columns: currentPageData.columns,
        Endpoints: currentPageData.endpoints,
        Defaults: {
          ...currentPageData?.defaults,
          ...customDefault,
        },
        DynamicSelectFields: currentPageData?.dynamicSelectFields,
        HiddenColumns: currentPageData?.hiddenColumns,
        HiddenFormFields: currentPageData?.hiddenFormFields,
        Docs: currentPageData?.docs,
        SubTable: currentPageData?.subTable,
        SelectedRecord: body,
        FriendlyName: currentPageData?.friendlyName,
        FormOnly: currentPageData?.formOnly,
        UniqueFetchKey: uniqueFetchKey,
        SortableOptions: currentPageData?.sortOptions ? currentPageData.sortOptions : [],
        Copy: currentPageData?.copy,
        LoadDefaults: currentPageData?.loadDefaults,
        CustomColumns: currentPageData?.customColumns,
        EnableSearchByName: currentPageData?.enableSearchbyName,
        NullableParents: currentPageData?.nullableParents,
        HistoryButton: currentPageData?.historyButton,
        HistoryView: currentPageData?.historyView,
        HistoryFilter: currentPageData?.historyFilter,
        NullableChildren: currentPageData?.nullableChildren,
        DoNotSendKeys: currentPageData?.doNotSendKeys,
        EnableSmartSearch: currentPageData?.smartSearch,
      },
    }));
    if (currentPageData?.formOnly) {
      setForceRemountKey(Date.now());
    }
    setInitialized(currentPageData?.base);
  }, [data]);

  return (
    <>
      <Box className={classes.rootTableContainer}>
        <Box sx={{ flex: "1" }}>
          {isLoadingOrNoData() ? (
            <RHDynamicLoadingMask loadMessage={loadMessage} showError={showError} />
          ) : (
            <RHEditableTable_v2
              key={forceRemountKey}
              apiName={null}
              rowKey={cachedData[selectedPage]?.RowKey}
              tableData={tableData}
              sandbox={currentStateData?.sandbox}
              hasEditPermission={userHasEditPermission}
              classes={classes}
              setNotificationProps={setNotificationProps}
              editableInline={false}
              retrieveSelectedRows={helpers.retrieveSelectedRows(setSelectedTableRow)}
              retrieveNewItemAsSelectedRow={helpers.retrieveNewItemAsSelectedRow(setSelectedTableRow)}
              onSelectedRowsChanged={helpers.onSelectedRowsChanged(setSelectedTableRow)}
              newModel={cachedData[selectedPage].Defaults}
              newModelArgs={[currentStateData?.sandbox?.sandboxId]}
              onDocs={cachedData[selectedPage]?.Docs ? onDocs : null}
              onImport={onImport}
              onExport={() => onExport(cachedData[selectedPage])}
              pageData={cachedData[selectedPage]}
              friendlyName={cachedData[selectedPage].FriendlyName}
              onFilterChangeHandler={onFilterChangeHandler}
              onPaginationChanged={onPaginationChangeHandler}
              onSortModelChanged={onSortModelChangeHandler}
              onColumnVisibilityModelChange={onColumnVisibilityModelChangeHandler}
              rowCount={rowCount}
              visibleColumnData={visibleColumns}
              paginationModel={paginationModelValue}
              sortModel={sortModelValue}
              onClearSettings={handleClearSettings}
              customQueryProps={pageSubtable}
              customQueryData={customQueryData}
              shouldCopy={cachedData[selectedPage]?.Copy}
              loadDefaults={cachedData[selectedPage]?.LoadDefaults ? loadDefaultValues : null}
              customDefaultId={props?.customDefaultId ? props?.customDefaultId : null}
              enableSearchByName={cachedData[selectedPage]?.EnableSearchByName}
              onHistory={cachedData[selectedPage]?.HistoryButton ? onHistory : null}
              historyView={cachedData[selectedPage]?.HistoryView}
              selectedSchemaObject={selectedPage}
              historyFilter={cachedData[selectedPage]?.HistoryFilter}
              enableSmartSearch={cachedData[selectedPage]?.EnableSmartSearch}
            />
          )}
          {/* The following condition will render the SubTable if certain conditions are met */}
          {shouldRenderRHDynamicPage() && (
            <Box sx={{ flex: "1", paddingTop: "20px" }}>
              <RHDynamicPage
                page={cachedData[selectedPage]?.SubTable?.schema}
                subTableOptions={options}
                pageSubtable={cachedData[selectedPage]?.SubTable}
                body={selectedTableRow}
                customDefaultId={cachedData[selectedPage]?.SubTable?.customDefaultId}
                pageIdentifier={cachedData[selectedPage]?.SubTable?.schema.toLowerCase()}
              />
            </Box>
          )}
        </Box>
      </Box>
      {/* Mui-less Notification */}
      {notificationProps.message && (
        <Notification
          message={notificationProps.message}
          severity={notificationProps.severity}
          clearMessage={clearNotification}
        />
      )}

      {/* Dialog for confirming import actions */}
      {/* <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">Confirm Import</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description" style={{ whiteSpace: "pre-line" }}>
            {`Records to be added: ${recordCounts.add}\nRecords to be deleted: ${recordCounts.delete}\nRecords to be updated: ${recordCounts.update}\n\nWould you like to Proceed?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmImport} color="primary" autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
