export const initialState = [];

export const overridesCreateReducer = (state, action) => {
  if (action.type === "UPDATE_STATE" && !Array.isArray(action?.payload?.value)) {
    throw new Error("Invalid payload value. This reducer expects an array.");
  }
  switch (action.type) {
    case "UPDATE_STATE":
      return action?.payload?.value;
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};

export const overridesPutReducer = (state, action) => {
  if (action.type === "UPDATE_STATE" && !Array.isArray(action?.payload?.value)) {
    throw new Error("Invalid payload value. This reducer expects an array.");
  }
  switch (action.type) {
    case "UPDATE_STATE":
      return action.payload.value;
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};

export const overridesDeleteReducer = (state, action) => {
  if (action.type === "UPDATE_STATE" && !Array.isArray(action?.payload?.value)) {
    throw new Error("Invalid payload value. This reducer expects an array.");
  }
  switch (action.type) {
    case "UPDATE_STATE":
      return action.payload.value;
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};
