import { Key } from "@mui/icons-material";
import { GRID_STRING_COL_DEF } from "@mui/x-data-grid";

export function capitalizeFirstLetter(string) {
  if (typeof string === "string" && string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
}

export function getErrorMessage(error, variables, context) {
  // Check if the error is structured like the payload you've provided
  const details = error?.response?.data?.detail;
  if (details && Array.isArray(details)) {
    const detail = details[0];
    const loc = detail.loc.join(" "); // Join location parts to create the location string
    const msg = detail.msg;
    // Return the formatted message
    return `${msg} (Location: ${loc})`;
  }

  // Attempt to extract detailed error message from various known structures
  const responseError = error?.response?.data?.desc;
  const detailError = processDetailError(error?.response?.data?.detail);
  const status = error?.response?.status;

  // Construct the final message, with a fallback if no details are found
  return responseError || detailError || `Error ${status || ""}: An error occurred.`.trim();
}

export function isFullWidthField(field) {
  return (
    field.selectType === "array" ||
    field.type === "json" ||
    field.type === "repeater" ||
    field.type === "customdata" ||
    (field.type === "enhancedautocomplete" && field.multiSelect === true)
  );
}

export function getGeneralFormStylesSx(field, isChild) {
  return {
    boxSizing: "border-box",
    height: isFullWidthField(field) ? "auto" : "56px",
    margin: isChild ? "10px" : "0",
    maxWidth: isFullWidthField(field) ? "none" : isChild ? "338px" : "360px",
    minWidth: "250px",
    width: field.width ?? "100%",
    marginBottom: "15px",
  };
}

export function getGeneralDisabledFormStylesSx(field) {
  return {
    boxSizing: "border-box",
    height: isFullWidthField(field) ? "auto" : "56px",
    maxWidth: isFullWidthField(field) ? "none" : "360px",
    minWidth: "250px",
    //fontStyle: "italic", // Make text italic
    width: field.width ?? "100%",
    marginBottom: "15px",
    //"& input": {
    //  backgroundColor: "#00000030", // Apply background color to input elements
    //},
  };
}

export function getGeneralAlwaysDisabledFormStylesSx(field) {
  return {
    boxSizing: "border-box",
    height: isFullWidthField(field) ? "auto" : "56px",
    maxWidth: isFullWidthField(field) ? "none" : "360px",
    minWidth: "250px",
    fontStyle: "italic", // Make text italic
    width: field.width ?? "100%",
    "& input": {
      backgroundColor: "#00000030", // Apply background color to input elements
    },
  };
}

export function scrollToBottom() {
  // let timeout = setTimeout(() => {
  //   clearTimeout(timeout);
  //   window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  // }, 100);
}

export function extractIdsFromPath(path) {
  if (!path) {
    console.warn("Invalid path provided to extractIdsFromPath");
    return [];
  }

  const regex = /{([^}]+)}/g;
  let ids = [];
  let match;

  while ((match = regex.exec(path)) !== null) {
    ids.push(match[1]);
  }

  return ids;
}

export function mapType(type, format, key, metadataKeys, dynamicSelectFields, description, customDefaultId) {
  // Maps specific schema type and format combinations to DataGrid-recognized types

  for (const dyKey in dynamicSelectFields) {
    if (dyKey.includes(".") && dyKey.split(".")[1] === key && key !== customDefaultId) {
      return "dynamicselectfield";
    }
    if (key === dyKey && key !== customDefaultId) {
      return "dynamicselectfield";
    }
  }

  if (key === "event_name") {
    return type;
  }
  // Additional logic based on item title
  if (
    (key.startsWith("legacy_") && !key.startsWith("legacy_id_generation") && !key.startsWith("legacy_time_frame_id")) ||
    key === "created_timestamp" ||
    key === "last_modified_timestamp" ||
    key === "last_modified_account_id" ||
    key === "account_id" ||
    key === "operation" ||
    key === "issued_at" ||
    (key === "sandbox_id" && !description.includes("Epic Online Services")) ||
    key === customDefaultId
  ) {
    return "metadata";
  }

  const metadatakeysArray = Array.isArray(metadataKeys) ? metadataKeys : [metadataKeys];

  // Check if key is in keysArray
  if (metadatakeysArray.includes(key)) {
    return "metadata"; // Return 'metadata' type if key matches
  }

  //UGH $$SAINT Remember to fix this
  //
  if (key === "title_ids" || key === "eu_service_labels" || key === "na_service_labels" || key === "key_types") {
    return "stringarray";
  }

  //ugh $$Saint Remember to fix this
  if (key === "custom_data") {
    return "customdata";
  }

  if (key === "rules" || key === "auto_browser_params" || key === "value_jsonschema" || key === "custom_headers") {
    return "json"; // Replace 'someOtherType' with the type you want to map to
  }

  return type;
}

export function createOrderedDataGridColumns(
  columns,
  visibleColumnNames,
  metadataKeys,
  dynamicSelectFields,
  hiddenColumns = [],
  sortableOptions = [],
  customDefaultId,
) {
  // Ensure hiddenColumns is always an array
  if (!Array.isArray(hiddenColumns)) {
    hiddenColumns = [];
  }

  // Filter out 'created_timestamp' column and hidden columns
  const filteredColumns = Object.keys(columns)
    .filter((key) => key !== "created_timestamp" && key !== "sandbox_id" && !hiddenColumns.includes(key))
    .reduce((acc, key) => {
      acc[key] = columns[key];
      return acc;
    }, {});

  // Create columns for visibleColumnNames first, in specified order
  const orderedVisibleColumns = visibleColumnNames
    .filter((key) => key in filteredColumns) // Ensure the key exists in filtered columns
    .map((key) =>
      createDataGridColumn(
        filteredColumns[key],
        key,
        metadataKeys,
        dynamicSelectFields,
        hiddenColumns,
        sortableOptions,
        customDefaultId,
      ),
    );

  // Find 'custom_data' column if it exists in filtered columns
  const customDataColumn = filteredColumns["custom_data"]
    ? createDataGridColumn(
        filteredColumns["custom_data"],
        "custom_data",
        metadataKeys,
        dynamicSelectFields,
        hiddenColumns,
        sortableOptions,
        customDefaultId,
      )
    : null;

  // Add remaining columns not in visibleColumnNames and not 'custom_data'
  const remainingColumns = Object.keys(filteredColumns)
    .filter((key) => !visibleColumnNames.includes(key) && key !== "custom_data")
    .map((key) =>
      createDataGridColumn(
        filteredColumns[key],
        key,
        metadataKeys,
        dynamicSelectFields,
        hiddenColumns,
        sortableOptions,
        customDefaultId,
      ),
    );

  // Combine orderedVisibleColumns and remainingColumns
  const combinedColumns = [...orderedVisibleColumns, ...remainingColumns];

  // Append 'custom_data' column at the end if it exists
  if (customDataColumn) {
    combinedColumns.push(customDataColumn);
  }

  return combinedColumns;
}

function createDataGridColumn(
  item,
  key,
  metadataKeys,
  dynamicSelectFields,
  hiddenColumns,
  sortableOptions,
  customDefaultId,
) {
  // Base column creation
  let sortable;
  if (sortableOptions && sortableOptions.length > 0) {
    sortable = sortableOptions.includes(key);
  } else {
    sortable = true;
  }

  let column = {
    field: key,
    headerName: item.title || key,
    type: mapType(item.type, item.format, key, metadataKeys, dynamicSelectFields, item.description, customDefaultId),
    flex: key.startsWith("legacy") || item.type === "number" ? 0 : 1,
    minWidth:
      key.endsWith("_id") && !key.startsWith("legacy")
        ? 325
        : key.startsWith("legacy") || item.type === "number"
          ? 150
          : null,
    info: item.description,
    valueFormatter: (params) => {
      if (!params.value) return "";
      if (item.format === "date-time") {
        // Create a new Date object
        const valueFormatted = new Date(params.value);

        // Format the date using Intl.DateTimeFormat
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(valueFormatted);
      }
    },
    required: item.required,
    selectType: item.type,
    filterable: false,
    sortable: sortable,
  };

  // Recursively handle children if they exist
  if (item.children && Object.keys(item.children).length > 0) {
    column.children = Object.keys(item.children)
      .filter((childKey) => childKey !== "endpoints" && !hiddenColumns.includes(childKey))
      .map((childKey) =>
        createDataGridColumn(item.children[childKey], childKey, metadataKeys, dynamicSelectFields, hiddenColumns),
      );
  }

  return column;
}

export const createColumnVisibilityModel = (columns, visibleColumns) => {
  return Object.keys(columns).reduce((model, key) => {
    model[key] = visibleColumns.includes(key);
    return model;
  }, {});
};

export const parseErrorObject = (errorObj) => {
  let details = [];

  // First, parse errorMsg which is a JSON string
  let parsedErrorMsg;
  try {
    parsedErrorMsg = JSON.parse(errorObj.errorMsg);
  } catch (e) {
    // If parsing fails, log the error and return a generic error message
    console.error("Error parsing errorMsg:", errorObj.errorMsg);
    return [
      {
        field: "ParsingError",
        message: "An error occurred while parsing the error message.",
        type: "unknown_error",
      },
    ];
  }

  if (parsedErrorMsg.detail && Array.isArray(parsedErrorMsg.detail)) {
    // Handle validation errors
    details = parsedErrorMsg.detail.map((detail) => ({
      field: detail.loc[detail.loc.length - 1],
      message: detail.msg,
      type: detail.type,
    }));
  } else if (parsedErrorMsg.error_code && parsedErrorMsg.desc) {
    // Handle integrity errors
    details.push({
      field: parsedErrorMsg.error_code,
      message: parsedErrorMsg.desc,
      type: "integrity_error",
    });
  } else {
    // Handle other unexpected error structures
    details.push({
      field: "Unknown",
      message: parsedErrorMsg.message || "An unknown error occurred",
      type: "unknown_error",
    });
  }

  return details;
};

export const restructureObject = (obj) => {
  const newObj = { ...obj }; // Copy the original object

  Object.keys(obj).forEach((key) => {
    if (key.includes(".")) {
      const parts = key.split(".");
      let current = newObj;

      parts.forEach((part, index) => {
        if (index === parts.length - 1) {
          // Merge with existing data instead of overwriting
          current[part] = { ...current[part], ...obj[key] };
        } else {
          // Create or merge with existing object
          current[part] = current[part] ? { ...current[part] } : {};
          current = current[part];
        }
      });

      delete newObj[key];
    }
  });

  return newObj;
};

export function collectRequiredKeys(obj, requiredKeys = new Set(), nullableParents = []) {
  // Check each property in the object
  for (const key in obj) {
    if (nullableParents && nullableParents.includes(key)) {
      continue; // Skip this iteration to avoid adding the key or processing its children
    }
    if (obj.hasOwnProperty(key) && typeof obj[key] === "object" && obj[key] !== null) {
      // If the object has children, recurse into the children first
      if (obj[key].children) {
        collectRequiredKeys(obj[key].children, requiredKeys);
      } else if (obj[key].required === true) {
        // Only add the key if it is required and does not have children
        requiredKeys.add(key);
      }
    }
  }
  return Array.from(requiredKeys); // Convert the Set back to an array to return
}
