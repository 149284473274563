import Tooltip from "components/common/FormField/Tooltip";
import { useLayout } from "contexts/LayoutContext";

const BasicSelect = ({ label, name, value, onChange, options = [], required, disabled, placeholder, description }) => {
  const { tableSize } = useLayout();

  return (
    <div className="mb-3 container-query-formfield">
      <div className="cq-wrapper">
        <label
          className={`block font-medium text-zinc-300 font-lato ${
            tableSize === "text-xs" || tableSize === "text-sm" ? "text-xs" : "text-sm"
          }`}
        >
          <Tooltip title={description} placement="left" disableInteractive>
            <p className="mb-1 cq-label">
              <span>{`${label} ${required ? "*" : ""}`}</span>
            </p>
          </Tooltip>
        </label>
        <select
          name={name}
          value={value || ""}
          onChange={onChange}
          required={required}
          disabled={disabled}
          className={`w-full text-white border border-zinc-600 rounded p-2 focus:outline-none focus:ring-2 focus:ring-violet-500 placeholder-zinc-400 bg-opacity-20 bg-zinc-700 backdrop-filter backdrop-blur-sm cq-input ${tableSize}`}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options?.map((option) => (
            <option key={option.id} value={option.id}>
              {option.title}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default BasicSelect;
