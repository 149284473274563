import { useState, useEffect } from "react";
import { Box, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Alert } from "@mui/material";

import { DataGrid, GridRowModes } from "@mui/x-data-grid";

import RHFieldWrapper from "./RHFieldWrapper";
import FormField from "components/common/RHEditableTable_v2/FormField";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import RHConfirmDialog from "./RHConfirmDialog";

import TableToolbar from "components/common/RHEditableTable_v2/TableToolbar";
import { useTableStyles } from "components/common/RHEditableTable_v2/styles";

export default function RHEditableTable(props) {
  const {
    apiName,
    title,
    rowKey,
    parentRowKey,
    columns,
    tableData,
    initialState,
    sandbox,
    nestedDataToUpdate,
    textFieldFilter,
    setSnackBar,
    setSnackBarState,
    friendlyName,
    friendlyNameKey,
    newModel,
    newModelArgs,
    retrieveSelectedRows,
    retrieveNewItemAsSelectedRow,
    onSelectedRowsChanged,
    selectionModel,
    createHandler,
    updateHandler,
    deleteHandler,
    filterMode,
    onFilterChanged,
    rowCount,
    paginationMode,
    onPaginationChanged,
    sortingMode,
    onSortModelChanged,
    disableEditMode,
    onDataRefresh,
    detailTitleOverride,
    version,
    searchOrCreateText,
    hideSearchBoxTitle,
    isRowEditable,
    disableRowClick,
    onCancel,
    onEdit,
    additionalEditButtons,
    customArgs,
    hasEditPermission,
    hideButtons,
    tabModel,
  } = props;

  const [selectedItem, setSelectedItem] = useState();
  const [editPermission, setEditPermission] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [newItemCreated, setNewItemCreated] = useState(false);
  const [editDetail, setEditDetail] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  // const [justDeleted, setJustDeleted] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [forceRemountKey, setForceRemountKey] = useState(Date.now());

  const [rowModesModel, setRowModesModel] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedInlineRows, setSelectedInlineRows] = useState();
  const [editableRows, setEditableRows] = useState();
  const [updatedRows, setUpdatedRows] = useState([]);
  const [localData, setLocalData] = useState();

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [selectedTab, setSelectedTab] = useState(tabModel ? tabModel[0]?.tabKey : null);

  const classes = useTableStyles();

  const isEditModeDisabled = disableEditMode === undefined ? false : disableEditMode;

  const [fieldErrors, setFieldErrors] = useState({});

  let defaultInitialState;

  if (!initialState) {
    defaultInitialState = {
      pagination: {
        paginationModel: {
          pageSize: 5,
        },
      },
      columns: {
        columnVisibilityModel: {
          last_modified_timestamp: false,
        },
      },
      sorting: {
        sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
      },
    };
  }

  const [dialogProps, setDialogProps] = useState({
    open: false,
    title: "",
    content: "",
    onConfirm: null,
  });

  useEffect(() => {
    setEditPermission(
      sandbox?.permissions?.includes("sandbox:config:edit") ||
        sandbox?.permissions?.includes("globalAdmin:*:*") ||
        hasEditPermission,
    );

    if (nestedDataToUpdate && tableData) {
      setLocalData(tableData[nestedDataToUpdate]);
    } else if (tableData) {
      setLocalData(tableData);
      selectionModel && setRowSelectionModel(selectionModel);
      // This looks like it was for hiding the detail view when the row was deleted, or if a new one was created. It must conflict with other functionality that selects newly created rows. Regardless, this is moot on this specific table that is now only for Price Points and Price Breakpoints. Breakpoints have unique ids, but are not unique objects returned from the api. they are in an array and we would have to traverse the new array's ids to see which one wasn'tm present before the breakpoint array was updated. Checking to see if it's worth it.

      // if (!selectionModel && editDetail) {
      //   setShowDetail(false);
      // }
    }
  }, [tableData]);

  const handleDialogConfirmDelete = async () => {
    try {
      setDialogProps((prev) => ({ ...prev, open: false }));

      await deleteHandler(
        apiName,
        onDataRefresh,
        selectedItem[rowKey],
        sandbox?.sandboxId,
        selectedItem,
        customArgs,
        selectedTab,
      );

      setEditedDetails();
      toggleShowDetail(false);
      setEditDetail(false);
      setNewItemCreated(false);
      setSnackBarState(true);
      setSnackBar({
        severity: "success",
        message: `Deleted "${selectedItem?.[friendlyNameKey ?? rowKey]}"`,
      });
    } catch (error) {
      console.error(error);
      setErrorMsg(getErrorMessage(error) || "An unknown error occurred");
      setSnackBarState(true);
      setSnackBar({
        severity: "error",
        message: `Failed to delete "${selectedItem?.[friendlyNameKey ?? rowKey]}", ${
          error?.response?.data?.detail || "An error occurred"
        }`,
      });
    }
  };

  const handleDialogCancel = () => {
    setDialogProps((prev) => ({ ...prev, open: false }));
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const processRowUpdate = (updatedRow) => {
    setUpdatedRows((prev) => [...prev, updatedRow]);
    setLocalData((prev) => {
      const prevIndex = prev.findIndex((row) => row?.[rowKey] === updatedRow[rowKey]);
      if (prevIndex > -1) {
        let newRows = Object.assign([], prev);
        newRows.splice(prevIndex, 1, updatedRow);
        if (isSaving) {
          actuallySave(newRows);
          setIsSaving(false);
        }
        return newRows;
      }
    });

    // MUI internally needs to have a row returned if you are using processRowUpdate that contains the row id
    return updatedRow;
  };

  const setGridModeBackToView = () => {
    return new Promise((resolve) => {
      let prevRowModel = { ...rowModesModel };
      Object.keys(prevRowModel).forEach((key) => {
        prevRowModel = {
          ...prevRowModel,
          [key]: { mode: GridRowModes.View, ignoreModifications: false },
        };
      });
      setRowModesModel({ ...prevRowModel });

      resolve();
    });
  };

  // This function will fire whenever after editing the user click away from the row
  const handleRowEditStop = (params) => {
    setRowModesModel((prev) => ({
      ...prev,
      [params.row[rowKey]]: { mode: GridRowModes.View },
    }));
  };

  const handleRowClick = (params) => {
    let item = params.row;
    retrieveSelectedRows && retrieveSelectedRows(params.row);
    setNewItemCreated(false);
    setSelectedItem(item);
    toggleShowDetail(true);
    setEditDetail(false);
    setEditedDetails(item);
  };

  const handleDelete = async () => {
    const snackDisplayId = selectedItem[friendlyNameKey]
      ? `\n\n${selectedItem[friendlyNameKey]}\n${selectedItem[rowKey]}\n\n`
      : detailTitleOverride
        ? detailTitleOverride(selectedItem[friendlyNameKey || rowKey], selectedItem)
        : `\n\n${selectedItem[rowKey]}\n\n`;

    if (!dialogProps.open) {
      setDialogProps({
        open: true,
        title: "Confirm Deletion",
        content: `Are you sure you want to delete? ${snackDisplayId} This action cannot be undone.`,
        onConfirm: () => handleDialogConfirmDelete(),
        onCancel: () => handleDialogCancel(),
      });
    }
  };

  function handleEdit() {
    setEditDetail(true);
    setNewItemCreated(false);
    setEditedDetails(selectedItem);
    onEdit && onEdit();
  }

  const setPersistentErrorMsg = (msg) => {
    setErrorMsg(msg);
  };

  const toggleShowDetail = (state) => {
    setShowDetail(state);
    setPersistentErrorMsg();
  };

  const handleCancel = () => {
    setForceRemountKey((forceRemountKey) => forceRemountKey + 1);
    setPersistentErrorMsg();
    if (newItemCreated) {
      toggleShowDetail(false);
    }
    setEditableRows();
    setEditDetail(false);
    setEditedDetails(selectedItem);
    if (nestedDataToUpdate) {
      setLocalData(tableData[nestedDataToUpdate]);
    } else setLocalData(tableData);
    onCancel && onCancel(newItemCreated);
  };

  const handleAddNew = () => {
    setRowSelectionModel([]);
    let newItem = new newModel(...newModelArgs);
    setNewItemCreated(true);
    setSelectedItem(newItem);
    setEditedDetails(newItem);
    setEditDetail(true);

    retrieveSelectedRows && retrieveNewItemAsSelectedRow && retrieveSelectedRows(null, /*isEdit*/ true);
    toggleShowDetail(true);
  };

  const prepareJsonForTransport = (editedItem) => {
    const jsonCols = columns.filter((x) => x.type == "json");
    jsonCols.forEach((x) => {
      if (editedItem[x.field] && typeof editedItem[x.field] !== "object") {
        let isValid = false;
        try {
          const editedItemCopy = { ...editedItem };
          editedItemCopy[x.field] = JSON.parse(editedItem[x.field]);
          isValid = true;
        } catch (error) {
          console.error(error);
        }
        if (isValid) {
          editedItem[x.field] = JSON.parse(editedItem[x.field]);
        } else return;
      }
    });
  };

  function validateData() {
    const errors = columns.reduce((acc, field) => {
      const fieldValue = editedDetails[field.field];
      const isEmpty = fieldValue === null || fieldValue === undefined || fieldValue.toString().trim() === "";
      const fieldError = fieldErrors[field.field];
      const isRequired = field.required && field.field !== rowKey;

      if (isRequired && isEmpty) {
        acc.push(`${field.headerName}: Required`);
      }

      if (fieldError && fieldError.isError) {
        const errorText = fieldError.helperText ? fieldError.helperText : "Invalid input";
        acc.push(`${field.headerName}: ${errorText}`);
      }

      if (field.type === "json" && fieldValue) {
        try {
          JSON.stringify(editedDetails[field.field]);
        } catch (error) {
          acc.push(field.headerName);
        }
      }
      return acc;
    }, []);

    if (errors.length > 0) {
      let newError = new Error("Validation Error");
      newError.response = {
        data: {
          desc: `${errors.join(", ")}`,
        },
      };
      throw newError;
    }
  }

  async function handleSave(newRows) {
    setPersistentErrorMsg();
    let item;
    let editedItem = Object.entries({ ...selectedItem, ...editedDetails }).reduce((acc, [key, value]) => {
      if (
        value === null ||
        value === undefined ||
        value === "" ||
        columns.find((column) => column.field === key && column.nullify)
      ) {
        return acc;
      } else {
        acc[key] = value;
        return acc;
      }
    }, {});

    prepareJsonForTransport(editedItem);

    try {
      validateData();
      if (newItemCreated && !nestedDataToUpdate) {
        item = await createHandler(apiName, onDataRefresh, sandbox?.sandboxId, editedItem, customArgs, selectedTab);
      } else {
        item = await updateHandler(
          apiName,
          onDataRefresh,
          editedItem[rowKey],
          sandbox?.sandboxId,
          editedItem,
          customArgs,
          selectedTab,
        );
      }

      setSnackBarState(true);
      const snack = editedDetails?.[friendlyNameKey ?? rowKey];
      setSnackBar({
        severity: "success",
        message: `Changes saved to ${detailTitleOverride ? detailTitleOverride(snack, item) : snack}`,
      });
      setNewItemCreated(false);

      const savedItem = selectedTab
        ? item
        : {
            ...editedItem,
            ...(Array.isArray(item.data) ? item.data[0] : (item?.data ?? item)),
          };

      setSelectedItem(savedItem);
      setEditedDetails(savedItem);
      setRowSelectionModel(savedItem[rowKey]);
      setEditDetail(false);
      setForceRemountKey(Date.now());
    } catch (error) {
      setSnackBarState(true);
      setSnackBar({ severity: "error", message: "Failed to save changes" });

      const serverMsg = getErrorMessage(error);
      if (serverMsg) {
        setPersistentErrorMsg(serverMsg);
      }
      console.error(error);
      return;
    }
  }

  const getErrorMessage = (error) => {
    const baseErrorMsg = extractBaseErrorMessage(error);
    const detailedErrorMsg = extractDetailedErrorMessage(error);

    return detailedErrorMsg || baseErrorMsg;
  };

  const extractBaseErrorMessage = (error) => {
    const errorData = error?.response?.data;
    if (!errorData) return null;

    return errorData.desc || errorData.detail || errorData;
  };

  const extractDetailedErrorMessage = (error) => {
    let serverErrorAltDetail = error?.response?.data?.detail;
    if (!serverErrorAltDetail) {
      let serverErrorAltDesc = error?.response?.data?.desc;
      if (serverErrorAltDesc && typeof serverErrorAltDesc == "string") {
        try {
          const descJson = JSON.parse(serverErrorAltDesc)?.detail;
          serverErrorAltDetail = descJson;
        } catch {}
      }
    }

    if (!serverErrorAltDetail) {
      return null;
    }

    let msg = serverErrorAltDetail[0]?.msg;
    const type = serverErrorAltDetail[0]?.type;
    const loc = serverErrorAltDetail[0]?.loc;
    if (type && loc && loc.length > 0) {
      msg = type === "value_error.any_str.min_length" ? "value required" : msg;
      let suffix = loc[loc.length - 1];
      for (const col of columns) {
        if (col.field === suffix) {
          suffix = col.headerName;
          break;
        }
      }
      msg = `${msg}: ${suffix}`;
      msg = msg.replaceAll("_id", "");
    }

    return msg;
  };

  function isFullWidthField(field) {
    return (
      field.type === "repeater" ||
      field.type === "json" ||
      (field.type === "enhancedautocomplete" && field.multiSelect === true)
    );
  }

  function getGeneralFormStylesSx(field, isChild) {
    return {
      boxSizing: "border-box",
      height: isFullWidthField(field) ? "auto" : "56px",
      margin: isChild ? "10px" : "0",
      maxWidth: isFullWidthField(field) ? "none" : isChild ? "338px" : "360px",
      minWidth: "250px",
      width: field.width ?? "100%",
    };
  }

  const handleValidationError = (fieldName, isError, helperText) => {
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: { isError, helperText },
    }));
  };

  const showDetailForm = () => {
    const hasRowSelected = rowSelectionModel && rowSelectionModel.length > 0;
    const rowIsSelectedOrIsNewItem = hasRowSelected || newItemCreated;

    return showDetail && rowIsSelectedOrIsNewItem;
  };

  function renderFormField(field, parentKeys = []) {
    if (field.type === "parent") {
      return (
        <div
          key={field.type + field.headerName + field.field}
          className="flex items-center flex-wrap justify-center w-full mb-[10px]"
        >
          <RHFieldWrapper
            label={parentKeys.length > 0 ? `${field.headerName} (${field.field})` : field.headerName}
            inactive={!editDetail ? !newItemCreated : undefined}
            isParent={true}
            uglyBorders={uglyBorders.has(field.headerName)}
            checkbox={false}
            checked={false}
            handleChange={undefined} // Correctly passing the handler
          >
            {field.children.map((child) => renderFormField(child, [...parentKeys, field.field]))}
          </RHFieldWrapper>
        </div>
      );
    } else {
      return (
        <div
          key={field.type + field.headerName + field.field}
          style={{
            ...getGeneralFormStylesSx(field, false),
            display: "inline-block",
            marginRight: "10px",
          }}
        >
          <FormField
            key={forceRemountKey}
            selectedItem={selectedItem}
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            editDetail={editDetail}
            field={field}
            rowKey={rowKey}
            // parentKey={parentKeys.length === 1 ? parentKeys[0] : parentKeys.join(".")}
            newItemCreated={newItemCreated}
            description={field.info}
            sandbox={sandbox}
            handleValidationError={handleValidationError}
            parentDataKey={field.parentDataKey}
            // hasError={hasError}
            // pageData={pageData}
            isFullWidthField={isFullWidthField(field)}
            setForceRemountKey={setForceRemountKey}
          />
        </div>
      );
    }
  }

  function renderTableDetailView() {
    return (
      <Box className={classes.tableDetailView}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              {detailTitleOverride
                ? detailTitleOverride(selectedItem[rowKey])
                : selectedItem[rowKey]
                  ? `${title ?? capitalizeFirstLetter(apiName)} Details - ${selectedItem[friendlyNameKey || rowKey]}`
                  : `New ${title ?? capitalizeFirstLetter(apiName)}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ bgcolor: "background.paper" }}>
            <Box className={classes.tableDetailViewContents}>
              <Box className={classes.tableDetailViewContentsButtonBar}>
                {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : <></>}
                {editDetail ? (
                  <div>
                    <Tooltip title="Exit edit mode">
                      <Button
                        onClick={() => {
                          handleCancel();
                        }}
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title={`Save ${apiName.toUpperCase()} Table`}>
                      <Button onClick={handleSave} variant="contained">
                        Save
                      </Button>
                    </Tooltip>
                  </div>
                ) : (
                  <div>
                    {editPermission && !isEditModeDisabled ? (
                      <>
                        <Tooltip title="Enter edit mode">
                          <Button
                            onClick={handleEdit}
                            variant="outlined"
                            disabled={isRowEditable && !isRowEditable(editedDetails)}
                          >
                            Edit
                          </Button>
                        </Tooltip>
                        {additionalEditButtons && additionalEditButtons.map((btn) => btn())}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </Box>

              <Box className={classes.tableDetailForm} style={{ marginBottom: "25px" }}>
                {columns
                  .filter((field) => !newItemCreated && field.type === "metadata")
                  .map((field, i) => (
                    <div
                      key={field.field + apiName + i + field.type}
                      style={{
                        ...getGeneralFormStylesSx(field),
                        display: "inline-block",
                      }}
                    >
                      {renderFormField(field, i)}
                    </div>
                  ))}
              </Box>

              <Box className={classes.tableDetailForm}>
                {columns
                  .filter((field) => field.type !== "metadata")
                  .filter(textFieldFilter ? textFieldFilter : () => true)
                  .map((field, i) => {
                    if (field.type === "parent") {
                      return (
                        <div
                          key={field.field + apiName + i + field.type}
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <RHFieldWrapper
                            label={field.headerName}
                            inactive={!editDetail ? !newItemCreated : undefined}
                            isParent={true}
                          >
                            {field.children.map((child, i) => (
                              <div
                                key={child.field + apiName + i + (child.type === undefined ? "text" : child.type)}
                                style={{
                                  ...getGeneralFormStylesSx(child, true),
                                }}
                              >
                                {renderFormField(child, i, field.field)}
                              </div>
                            ))}
                          </RHFieldWrapper>
                        </div>
                      );
                    } else if (field.editable === "createOnly") {
                      return (
                        <div
                          key={field.field + apiName + i + (newItemCreated ? (field.type ?? "text") : "metadata")}
                          style={{
                            ...getGeneralFormStylesSx({
                              ...field,
                              editable: newItemCreated ? true : false,
                            }),
                            display: "inline-block",
                          }}
                        >
                          {renderFormField(
                            {
                              ...field,
                              editable: newItemCreated ? true : false,
                            },
                            i,
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={field.field + apiName + i + field.type}
                          style={{
                            ...getGeneralFormStylesSx(field),
                            display: "inline-block",
                          }}
                        >
                          {renderFormField(field, i)}
                        </div>
                      );
                    }
                  })}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
  const visibleColumns = columns.filter((x) => !x.alwaysHidden);

  const onFilterChangeHandler = async (filterModel) => {
    if (version === 2) {
      onFilterChanged(filterModel);
    } else {
      if (filterMode == "server") {
        if (onFilterChanged) {
          let filter = {};
          filter.quickFilterValue = filterModel && filterModel.quickFilterValues[0];
          const nameFilter = (filterModel?.items ?? []).find((x) => x.field == "name");
          if (nameFilter) {
            filter.items = [{ field: nameFilter.field, value: nameFilter.value }];
          }
          onFilterChanged(filter);
        }
      }
    }
  };

  const onPaginationChangeHandler = async (options) => {
    if (paginationMode == "server") {
      if (onPaginationChanged) {
        onPaginationChanged(options);
      }
    }
  };

  const onSortModelChangeHandler = async (sortModel) => {
    if (sortingMode == "server") {
      if (onSortModelChanged) {
        onSortModelChanged([...sortModel]);
      }
    }
  };

  return (
    <>
      <Box className={classes.rootConfigPage}>
        {localData && (
          <DataGrid
            {...props}
            className={classes.table}
            initialState={initialState ?? defaultInitialState}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            getRowId={(row) => row[rowKey]}
            rowHeight={32}
            onRowClick={disableRowClick ? () => {} : handleRowClick}
            rows={tabModel && selectedTab ? localData?.[selectedTab] : localData}
            columns={visibleColumns.filter((col) => col.type !== "parent")}
            filterMode={filterMode ?? "client"}
            onFilterModelChange={onFilterChangeHandler}
            pagination
            paginationMode={paginationMode ?? "client"}
            onPaginationModelChange={onPaginationChangeHandler}
            sortingMode={sortingMode ?? "client"}
            onSortModelChange={onSortModelChangeHandler}
            rowCount={rowCount}
            autoHeight
            editMode="row"
            rowModesModel={rowModesModel}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onRowSelectionModelChange={(items) => {
              setSelectedInlineRows(items);
              setRowSelectionModel(items);
              setForceRemountKey(Date.now());
              onSelectedRowsChanged && onSelectedRowsChanged(items);
            }}
            rowSelectionModel={rowSelectionModel}
            slots={{
              toolbar: () =>
                TableToolbar({
                  handleAddNew,
                  handleCancel,
                  handleDelete,
                  handleEdit,
                  handleSave,
                  setSelectedTab,
                  selectedTab: selectedTab,
                  hasEditPermission: editPermission,
                  selectedItem,
                  classes,
                  apiName,
                  hideHeader: true,
                  selectedInlineRows,
                  activelyEditing: editableRows ? true : false,
                  //friendlyName: !friendlyNameKey ? columns[0].headerName : undefined,
                  friendlyName: !friendlyName ? (!friendlyNameKey ? columns[0].headerName : undefined) : friendlyName,
                  title: title,
                  hideSearchBoxTitle: hideSearchBoxTitle,
                  isDirty: isDirty,
                  searchOrCreateText: searchOrCreateText,
                  hideButtons,
                  tabModel: tabModel ?? undefined,
                }),
            }}
            sx={{
              bgcolor: "background.paper",
              border: "none",
              minWidth: 0,
              p: 1,
              "& .MuiDataGrid-cell": {
                //fontSize: ".9rem",
                fontWeight: "light",
                border: 0,
              },
              "& .MuiDataGrid-columnHeader": {
                fontWeight: "bold",
                paddingRight: "40px",
              },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: "rgba(245, 245, 245, .025)", // Light background for odd rows
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#9d69ff29", // Highlight row on hover
              },
              "& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover": {
                backgroundColor: "#9d69ff50", // Custom background for selected rows
                color: "#FFF",
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                justifyContent: "space-between",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              },
              "& .MuiDataGrid-menuIconButton": {
                visibility: "visible !important",
                position: "absolute",
                right: "5px",
                top: "50%",
                transform: "translateY(-50%)",
              },
              // your other styles...
              "& .MuiDataGrid-root": {
                "--unstable_DataGrid-radius": "0px",
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                // your existing styles
                "--unstable_DataGrid-radius": "4px",
              },
            }}
          />
        )}
        {showDetailForm() && <Box className={classes.tablesDetailContainer}>{renderTableDetailView()}</Box>}
        <RHConfirmDialog
          open={dialogProps.open}
          onConfirm={dialogProps.onConfirm}
          onCancel={dialogProps.onCancel}
          title={dialogProps.title}
          content={dialogProps.content}
        />
      </Box>
    </>
  );
}
