import React, { useState, useEffect, useMemo, useReducer, useContext } from "react";
import { createPortal } from "react-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "contexts/AuthContext";
import { useLayout } from "contexts/LayoutContext";
import AccountContext from "contexts/AccountContext";

import { formReducer, initialState, initialMutationsState, mutationsReducer } from "./formReducer";

import { apiRequest, getErrorMessage, usePortalQuery, usePortalMutation, validateData } from "common/apiUtils";

import FormField from "components/common/FormField";
import LoadingMask from "components/common/DynamicLoadingMask";
import MenuBar from "components/common/MenuBar";
import Modal from "components/common/Modal";
import FlashText from "components/common/FormField/FlashText";
import SkeletonLoader from "components/common/SkeletonLoader";
import definitions from "common/definitions.json";

import PermissionsEditor from "./PermissionsEditor";
import GroupPermissionsEditor from "./GroupPermissionsEditor";

function getTabs(config) {
  const editor = config?.editor;
  if (!editor) return [];

  let tabs = Object.keys(editor).filter((key) => key !== "group");

  if (editor.group) {
    tabs = tabs.concat(Object.keys(editor.group?.tabs));
  }
  return tabs;
}

function getEditorConfig(config, editorTab) {
  return config?.editor[editorTab] ? config?.editor[editorTab] : undefined;
}

export default function Form({
  item,
  config,
  tab,
  setSelectedRow,
  setRowSelectionModel,
  setDrawerLoadingMessage,
  newModel,
  buttonsMap,
  readPermitted,
  editPermitted,
}) {
  const { getAccessToken } = useAuth();
  const { currentStateData } = useContext(AccountContext);
  const { tableSize } = useLayout();
  const queryClient = useQueryClient();

  const tabs = getTabs(config);
  const defaultTab = useMemo(() => {
    // Ensure config and config.editor are defined
    const editorConfig = config?.editor || {};

    // Use default tab if set, otherwise, use the first tab
    return (
      newModel?.tab ??
      Object.keys(editorConfig).find((key) => editorConfig[key]?.default) ??
      Object.keys(editorConfig)[0]
    );
  }, [config, newModel]);

  const [editorTab, setEditorTab] = useState(defaultTab);

  const editorConfig = getEditorConfig(config, editorTab);
  const stateKey = useMemo(() => editorConfig?.schemas?.GET, [editorConfig]);

  const [state, dispatch] = useReducer(formReducer, initialState);
  const [mutationsRegistry, dispatchMutationsRegistry] = useReducer(mutationsReducer, initialMutationsState);

  const [error, setError] = useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [allRequired, setAllRequired] = useState(false);
  const [mutationStates, setMutationStates] = useState({});
  const [prevItem, setPrevItem] = useState();
  const [openFlashText, setOpenFlashText] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Create replacements object for the API call with _id and _identifier
  let replacements;
  if (item?.[config?.rowId]) {
    replacements = Object.entries(item).reduce((acc, [key, value]) => {
      const idRegex = /id$/;
      if (idRegex.test(key)) {
        acc[key] = value;
        acc[key.replace("id", "identifier")] = value;
      }
      return acc;
    }, {});
  }
  const accountQuery = usePortalQuery({
    schema: editorConfig?.schemas?.GET,
    token: getAccessToken(),
    replacements,
    configs: editorConfig,
    newModel: newModel?.fields,
  });

  const updateMutation = usePortalMutation({
    queryClient,
    schema: editorConfig?.schemas?.MUTATE,
    token: getAccessToken(),
    replacements,
    configs: editorConfig,
    method: "put",
    onSuccessCallback: () => {
      setIsDirty(false);
      setAllRequired(false);

      // Delay the success message to allow the Save/Cancel buttons to animate off
      // and Success to animate on
      const timeout1 = setTimeout(() => {
        setShowSuccessMessage(true);
        clearTimeout(timeout1);
      }, 200);
      const timeout2 = setTimeout(() => {
        setShowSuccessMessage(false);
        clearTimeout(timeout2);
      }, 2500);

      queryClient.invalidateQueries({ queryKey: [tab] });
      queryClient.invalidateQueries({
        queryKey: [editorConfig?.schemas?.MUTATE, replacements],
      });
    },
    onErrorCallback: () => {
      const errorMsg = getErrorMessage(error, editorConfig?.fields);
      setError(errorMsg);
    },
  });

  const deleteMutation = usePortalMutation({
    queryClient,
    schema: editorConfig?.schemas?.DELETE,
    token: getAccessToken(),
    replacements,
    configs: editorConfig,
    method: "delete",
    onMutateCallback: () => {
      setDrawerLoadingMessage("Deleting the account, please wait...");
    },
    onErrorCallback: () => {
      const errorMsg = getErrorMessage(error, editorConfig?.fields);
      setError(errorMsg);
    },
    onSuccessCallback: (data) => {
      setSelectedRow && setSelectedRow();
      setRowSelectionModel({});
      setDrawerLoadingMessage();

      queryClient.refetchQueries({ queryKey: [tab] });
    },
  });

  const createMutation = usePortalMutation({
    queryClient,
    schema: editorConfig?.schemas?.POST,
    token: getAccessToken(),
    replacements,
    configs: editorConfig,
    method: "post",
    onErrorCallback: (error) => {
      const errorMsg = getErrorMessage(error, editorConfig?.fields);
      setError(errorMsg);
      setDrawerLoadingMessage();
    },
    onMutateCallback: () => {
      setDrawerLoadingMessage("Creating the account, please wait...");
    },
    onSuccessCallback: (data) => {
      setSelectedRow && setSelectedRow();
      setRowSelectionModel({ [data[config?.rowId]]: true });
      setDrawerLoadingMessage();

      queryClient.refetchQueries({ queryKey: [tab] });
    },
  });

  function toggleModal(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowModal((prev) => !prev);
  }

  function handleCancel() {
    setError();
    newModel ? handleChange(newModel?.fields) : handleChange(accountQuery.data);
    setIsDirty(false);
  }

  function handleSave() {
    const errors = validateData(
      state?.[stateKey],
      newModel ? editorConfig?.fields : editedFieldsToValidate,
      config.rowId,
    );
    if (errors.length > 0) {
      setError(errors.join(", "));
      return;
    }

    setError(null);

    const mutationKeys = Object.keys(mutationsRegistry).reduce((acc, key) => {
      if (state?.[key]) acc.push(key);
      return acc;
    }, []);

    setMutationStates(mutationKeys.reduce((acc, key) => ({ ...acc, [key]: "pending" }), {}));
    // All of the variables that are saved in the mutationsRegistry are the ones that are being used
    // in the mutations. Because this is very async and pointing to different parts of the api,
    // .mutateAsync is used to let us make manual calls rather than relying on react-query's
    // normal helpful methods.

    const mutationPromises = mutationKeys.map((key) => {
      let data = state?.[key];
      // If the data is not an array, we need to add the assigned_org_id
      // In this case, only GroupPermissionsEditor sends up an array.
      if (!Array.isArray(data)) {
        data = {
          ...state?.[key],
          assigned_org_id: currentStateData?.org?.orgId,
        };
      }
      const { schema, editorConfig, replacements } = mutationsRegistry?.[key];

      if (key && data) {
        setDrawerLoadingMessage("Saving, please wait...");

        return updateMutation
          .mutateAsync({
            data,
            schemaOverride: schema,
            configsOverride: editorConfig,
            replacementsOverride: replacements,
          })
          .then((result) => {
            dispatch({
              type: "UPDATE_STATE",
              payload: { stateKey: schema, value: result },
            });

            setMutationStates((prev) => ({ ...prev, [key]: "success" }));

            queryClient.refetchQueries({
              queryKey: [schema, replacements],
            });

            return { editorTab, status: "success" };
          })
          .catch((error) => {
            setMutationStates((prev) => ({ ...prev, [key]: "error" }));
            throw error; // Rethrow to be caught in the main catch block
          });
      }
      return Promise.resolve();
    });

    Promise.all(mutationPromises)
      .then((results) => {
        return results;
      })
      .catch((error) => {
        console.log(error);
        const errorMsg = getErrorMessage(error, editorConfig?.editor);
        setError(errorMsg);
      })
      .finally(() => {
        const isAnyPending = Object.values(mutationStates).some((state) => state === "pending");

        if (!isAnyPending) {
          setError();
          setIsDirty(false);
          setAllRequired(false);
          setDrawerLoadingMessage();
          queryClient.refetchQueries({ queryKey: [tab] });
        }
      });
  }

  function handleDelete() {
    setShowModal(false);
    deleteMutation.mutate();
  }

  function handlePost() {
    const errors = validateData(state?.[stateKey], editorConfig?.fields, config.rowId);
    if (errors.length > 0) {
      setError(errors.join(", "));
    } else {
      setError();
      createMutation.mutate({
        data: { ...state?.[stateKey], org_id: currentStateData?.org?.orgId },
      });
    }
  }

  const editedFieldsToValidate = useMemo(() => {
    const editor = config?.editor;
    if (!editor) return [];

    let fields = [];

    if (editor[editorTab]?.fields) {
      fields = fields.concat(editor[editorTab].fields);
    }

    return fields.filter((field) => field?.editable !== "hideAfterCreate");
  }, [editorTab, config]);

  function handleChange(value) {
    dispatch({
      type: "UPDATE_STATE",
      payload: { stateKey, value },
    });
  }

  function setDataWithDirtyCheck(newData, initial = false) {
    handleChange(newData);

    // initial is used to prevent the dirty check on the initial data load.
    // This is needed for instances where the data is loaded into a child that
    // immediately needs to push it to the state manager. In this case, the
    // PermissionsEditor.
    if (!isDirty && !initial) {
      setIsDirty(true);
    }
    if (!initial) {
      dispatchMutationsRegistry({
        type: "UPDATE_MUTATIONS_REGISTRY",
        payload: {
          stateKey,
          value: {
            schema: editorConfig?.schemas?.MUTATE,
            editorConfig,
            replacements,
          },
        },
      });
    }
  }

  function filterOmitForNewModel(field) {
    return field?.type === "metadata" && field?.editable !== "lockAfterCreate" && newModel?.fields ? false : true;
  }

  function filterOmitNotNewModel(field) {
    if (field?.editable === "hideAfterCreate" && !newModel) return false;
    return true;
  }

  async function handleSendPasswordResetEmail(e) {
    e.preventDefault();
    e.stopPropagation();
    // Hardcoding this complete one-off in.
    await apiRequest(
      getAccessToken(),
      {
        method: "POST",
        path: "/v1/account_pwd_reset/{account_identifier}",
      },
      { account_identifier: item?.[config?.rowId] },
    );

    setOpenFlashText(true);
  }

  const handleFlashTextClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFlashText(false);
  };

  useEffect(() => {
    if (accountQuery.data && prevItem?.[config?.rowId] !== item?.[config?.rowId]) {
      dispatch({
        type: "INIT_STATE",
        payload: { stateKey, value: accountQuery.data },
      });
      dispatchMutationsRegistry({
        type: "RESET_DATA",
      });
      setPrevItem(item);
    } else if (accountQuery.data && !state?.[stateKey]) {
      // This happens when a tab is switched and the data is not yet loaded
      dispatch({
        type: "UPDATE_STATE",
        payload: { stateKey, value: accountQuery.data },
      });
    }
  }, [accountQuery.data]);

  useEffect(() => {
    if (!state?.[stateKey]) return;
    const fields = newModel ? editorConfig?.fields : editedFieldsToValidate;
    const errors = validateData(state?.[stateKey], fields, config.rowId);

    if (errors.length === 0) setAllRequired(true);
    else setAllRequired(false);
  }, [editorTab, state?.[stateKey]]);

  useEffect(() => {
    if (newModel) {
      setEditorTab(newModel?.tab);
      dispatch({
        type: "INIT_STATE",
        payload: {
          stateKey: config?.editor?.[newModel?.tab].schemas.GET,
          value: newModel?.fields,
        },
      });
      setPrevItem(item);
      setIsDirty(false);
    }
  }, [newModel]);

  useEffect(() => {
    setIsDirty(false);
    if (!item) {
      setWidthFn(0);
    }
    if (prevItem?.[config?.rowId] !== item?.[config?.rowId]) {
      setError();
      setEditorTab(defaultTab);
    }
  }, [item]);

  // const loadMessage = accountQuery.isError
  //   ? `Unable to retrieve data. ${accountQuery?.error?.message}`
  //   : "Retrieving the latest data, please wait...";

  return (
    <div className="h-full pl-1">
      {tabs.length > 0 && (
        <MenuBar
          className="sticky top-0"
          buttons={buttonsMap}
          tabs={newModel ? [] : tabs}
          setActiveTab={setEditorTab}
          activeTab={editorTab}
        />
      )}
      <div className="flex items-center justify-end bg-[#121212] w-full py-2 px-2 border-l border-r border-b border-zinc-800 relative overflow-hidden">
        <div
          className={`flex gap-2 transition-all duration-300 relative z-20 ${
            isDirty && allRequired && editPermitted ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
          }`}
        >
          <button
            onClick={handleCancel}
            className={`bg-zinc-600 text-white font-medium rounded-sm text-center px-1 py-1 transition-colors duration-200 ease-in-out hover:bg-zinc-700 w-20 ${tableSize}`}
          >
            Cancel
          </button>
          <button
            onClick={newModel ? handlePost : handleSave}
            className={`bg-violet-600 text-white font-medium rounded-sm text-center px-1 py-1 transition-colors duration-200 ease-in-out hover:bg-violet-700 w-20 ${tableSize}`}
          >
            {updateMutation.isPending ? "Saving..." : "Save"}
          </button>
        </div>

        {showSuccessMessage && (
          <div className="absolute top-1/2 right-3 transform -translate-y-1/2 animation-fade-in-out">
            <div className="p-1 text-xs rounded-sm font-medium text-green-500 border border-green-500/20 bg-green-900/20">
              Success
            </div>
          </div>
        )}
      </div>
      <div
        className={`text-red-500 grid transition-all px-3 ${
          accountQuery.isError || !!error ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
        }`}
      >
        <p
          className={`overflow-hidden font-lato border-red-500/20 rounded-sm ${
            accountQuery.isError || !!error ? "p-2 border-2 mt-3" : "p-0 border-0"
          } box-border`}
        >
          {accountQuery.isError ? getErrorMessage(accountQuery.error, config?.[editorTab]?.editor) : ""}
          {error && error.split("\n").map((error) => <React.Fragment key={error}>{error}</React.Fragment>)}
        </p>
      </div>
      <form className="relative p-2 min-h-[280px] h-[calc(100vh-126px)]">
        {(accountQuery.isFetching || accountQuery.isPending) && !newModel && (
          <div className="top-0 absolute left-0 w-full h-full flex items-center justify-center bg-black/40 z-[60]">
            {!state?.[stateKey] && (
              <div className="absolute top-0 left-0 w-full h-full">
                {editorConfig?.fields
                  .filter(filterOmitForNewModel)
                  .filter(filterOmitNotNewModel)
                  .map((field) => (
                    <SkeletonLoader
                      key={field.field}
                      type={field.type ?? "text"}
                      className="opacity-50 mb-4 w-full pl-10 pr-3 pt-4"
                    />
                  ))}
              </div>
            )}
            <LoadingMask loadMessage={""} className="z-[60]" />
          </div>
        )}
        {(editorTab === "User Permissions" || editorTab === "Permissions") && (
          <PermissionsEditor
            originalValue={item}
            fieldGroup={state?.[stateKey]}
            onChange={setDataWithDirtyCheck}
            permissionsKey={editorTab === "User Permissions" && "account_permissions"}
            editPermitted={editPermitted}
          />
        )}
        {editorTab === "Group Permissions" && (
          <GroupPermissionsEditor
            originalValue={item}
            fieldGroup={state?.[stateKey]}
            onChange={setDataWithDirtyCheck}
            rowKey={"group_permissions"}
            editPermitted={editPermitted}
          />
        )}
        {editorTab !== "User Permissions" && editorTab !== "Group Permissions" && (
          <div className={`${updateMutation.isPending ? "pointer-events-none" : ""}`}>
            {(accountQuery?.isSuccess || newModel) && state?.[stateKey] && (
              <div>
                {editorConfig?.fields
                  .filter(filterOmitForNewModel)
                  .filter(filterOmitNotNewModel)
                  .map((field) => (
                    <div key={Object.entries(field).join()}>
                      <FormField
                        field={{
                          ...field,
                          type:
                            field?.editable === "hideAfterCreate" && newModel
                              ? field?.type || "text"
                              : field?.editable === "lockAfterCreate" && !newModel
                                ? "metadata"
                                : field.type || "text",
                        }}
                        rowKey={field.field}
                        fieldGroup={state?.[stateKey]}
                        onChange={setDataWithDirtyCheck}
                        originalValue={item}
                        description={
                          definitions?.schemas?.[editorConfig?.schemas?.GET ?? config.schema]?.[field.field]
                            ?.description
                        }
                        disabled={!editPermitted}
                      />
                    </div>
                  ))}
                {editorTab === "Info" && tab === "Users" && !newModel && (
                  <div className="mt-10 text-right relative">
                    <button
                      className={`bg-violet-600 text-white font-medium rounded-sm text-center inline-flex items-center z-10 px-2 py-1 gap-2 transition-colors duration-200 ease-in-out hover:bg-violet-500 ${tableSize}`}
                      onClick={handleSendPasswordResetEmail}
                    >
                      Send Password Reset Email
                    </button>
                    <FlashText
                      open={openFlashText}
                      autoHideDuration={1000}
                      onClose={handleFlashTextClose}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      message={<div className="text-center">{"Email sent!"}</div>}
                    />
                  </div>
                )}
                {editorTab === "Info" &&
                  !updateMutation.isPending &&
                  !accountQuery.isFetching &&
                  !accountQuery.isError &&
                  !newModel &&
                  editPermitted && (
                    <div className="text-right">
                      <button
                        onClick={toggleModal}
                        className={`text-white mt-12 font-medium rounded-sm text-center inline-flex items-center z-10 px-3 py-1 gap-2 transition-colors duration-200 ease-in-out bg-rose-800 hover:bg-pink-950 ${tableSize}`}
                        title="Delete"
                      >
                        Delete
                      </button>
                    </div>
                  )}
              </div>
            )}
          </div>
        )}
      </form>
      {showModal &&
        createPortal(
          <Modal
            isOpen={showModal}
            onClose={toggleModal}
            onConfirm={() => handleDelete()}
            title={"Are you sure?"}
            message={`You are about to delete ${item?.org_group_name ?? item?.account_email ?? item?.description}. ${
              item?.group_id ? `This will impact all members of this group.` : ""
            }`}
          />,
          document.body,
        )}
    </div>
  );
}
