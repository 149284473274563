import { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import Tooltip from "components/common/FormField/Tooltip";
import { debounce } from "common/helpers";

import { useLayout } from "contexts/LayoutContext";

export default function SearchAndSelect({
  label,
  name,
  value,
  onSelect,
  onChange,
  options = [],
  required,
  disabled,
  placeholder,
  description,
}) {
  const { tableSize } = useLayout();
  const componentRef = useRef(null);
  const portalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  function updatePortalPosition() {
    if (componentRef.current) {
      const rect = componentRef.current.getBoundingClientRect();
      return {
        position: "absolute",
        top: `${rect.bottom + window.scrollY + 2}px`,
        left: `${rect.left + window.scrollX}px`,
        width: `${rect.width}px`,
      };
    }
    return {};
  }

  const updateTypeaheadValue = debounce((event) => {
    const { value } = event.target;
    if (event && value) {
      onChange(value);
    } else {
      onChange("");
    }
  }, 400);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target) &&
        portalRef.current &&
        !portalRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="mb-3 container-query-formfield relative" ref={componentRef}>
      <div className="cq-wrapper relative">
        <label
          className={`block font-medium text-zinc-300 font-lato ${
            tableSize === "text-xs" || tableSize === "text-sm" ? "text-xs" : "text-sm"
          }`}
        >
          <Tooltip title={description} placement="left" disableInteractive>
            <p className="mb-1 cq-label">
              <span>{`${label} ${required ? "*" : ""}`}</span>
            </p>
          </Tooltip>
        </label>
        <input
          className="text-zinc-300 bg-zinc-800 border border-zinc-600 p-[7px] rounded-[4px] box-border focus:outline-violet-600 outline-violet-600"
          type="text"
          onFocus={() => setIsOpen(true)}
          onChange={updateTypeaheadValue}
        />
        {isOpen &&
          createPortal(
            <div
              style={updatePortalPosition()}
              ref={portalRef}
              className="bg-zinc-800 text-zinc-300 shadow-xl rounded-bl-lg rounded-br-lg border border-zinc-700 z-[100] max-h-[300px] overflow-y-scroll py-1 px-2"
            >
              <AnimatePresence mode="popLayout" initial={false}>
                {options.map((option) => (
                  <motion.div
                    layout
                    key={option.id}
                    className={` cursor-pointer px-2 py-1 flex items-center ${tableSize} last:pb-0 justify-between bg-zinc-800 hover:bg-zinc-700 rounded-md`}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                      transition: { type: "spring", stiffness: 300, damping: 25, opacity: { duration: 0.1 } },
                    }}
                    exit={{ opacity: 0, scale: 0.8, transition: { duration: 0.1 } }}
                  >
                    {option.title}
                    <button
                      className={`bg-violet-600 text-white font-medium rounded-sm text-center inline-flex items-center z-10 py-1 px-2 gap-2 transition-colors duration-200 ease-in-out hover:bg-violet-500 ${tableSize === "text-xs" ? "text-xs" : "text-sm"}`}
                      onClick={() => onSelect(option)}
                    >
                      Add
                    </button>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>,
            document.body,
          )}
      </div>
    </div>
  );
}
