import React from "react";
import Tooltip from "components/common/FormField/Tooltip";

export default function FieldWrapper({
  label,
  children,
  inactive,
  isParent,
  disabled,
  required,
  fullWidth,
  autoInactiveChild,
  description,
  uglyBorders,
  checkbox,
  checked,
  handleChange,
}) {
  const modifiedChildren = React.Children.map(children, (child) =>
    React.isValidElement(child) ? React.cloneElement(child, { disabled }) : child,
  );

  return (
    <div
      className={`box-border ${isParent ? "flex ml-0 mt-0" : "inline-block ml-[-1px] mt-[-7px]"} min-w-[252px] ${
        isParent || fullWidth ? "max-w-full" : "max-w-[359px]"
      } w-full`}
    >
      <fieldset
        className={`box-border ${fullWidth ? "block" : "flex"} ${
          isParent ? "flex-row flex-wrap" : "flex-col flex-nowrap"
        } items-center justify-center ${
          inactive || uglyBorders ? "border border-white/30" : "border border-white/50"
        } ${uglyBorders ? "" : "border border-white/30"} rounded-sm ${
          inactive ? "text-white/50" : "text-white/70"
        } font-roboto font-normal ${
          isParent || fullWidth ? "h-auto" : "h-[63px]"
        } ml-[2px] ${isParent ? "min-h-[100px]" : "min-h-auto"} ${
          uglyBorders ? "" : isParent ? "p-5" : "p-[0_7px_4px]"
        } w-full`}
      >
        {!uglyBorders && (
          <Tooltip title={description ?? ""} placement="left" disableInteractive>
            <legend className="flex items-center px-[6px] text-[0.75em]">
              {label}
              {required ? "*" : ""}
              {checkbox && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <span style={{ marginRight: "5px" }}> -- Enabled:</span>
                  <input type="checkbox" checked={checked} onChange={handleChange} disabled={inactive} />
                </div>
              )}
            </legend>
          </Tooltip>
        )}
        <div
          className={`p-${
            inactive && autoInactiveChild ? "opacity-70" : "opacity-100"
          } ${isParent ? "flex" : "block"} flex-wrap ${isParent || fullWidth ? "w-full" : "w-auto"}`}
          aria-description={description ?? ""}
        >
          {modifiedChildren}
        </div>
      </fieldset>
    </div>
  );
}
