import { useState, useEffect, Fragment, useCallback } from "react";
import FieldWrapper from "./FormField/FieldWrapper";
import TextField from "./FormField/TextField";
import { PlusIcon, MinusIcon } from "assets/svgs";
import { motion, AnimatePresence } from "framer-motion";

export default function CustomDataField({
  label,
  name,
  value,
  disabled,
  onChange,
  required,
  description,
  setEditedDetails,
  rowKey,
  resetFlag,
}) {
  const [prevRowKey, setPrevRowKey] = useState(rowKey);
  const [localSelectedItem, setLocalSelectedItem] = useState([]);

  const getFieldKey = useCallback((rowIndex, fieldIndex) => `field-${rowIndex}-${fieldIndex}`, []);

  function addRow() {
    setLocalSelectedItem((prev) => [...prev, ["", ""]]);
  }

  function deleteRow(rowIndex) {
    if (localSelectedItem.length > 1) {
      setLocalSelectedItem((prev) => prev.filter((_, index) => index !== rowIndex));
    } else {
      setLocalSelectedItem([["", ""]]);
    }
  }

  function initializeLocalSelectedItem() {
    if (!value || Object.keys(value).length === 0) {
      setLocalSelectedItem([["", ""]]);
    } else {
      // Converting the json to an array ensures the order of the keys is maintained and both the keys and values can be easily edited.
      setLocalSelectedItem(Object.entries(value));
    }
  }

  function isEmptyObject(arrayEntries) {
    return arrayEntries.length === 0 || (arrayEntries.length === 1 && arrayEntries[0][0] === "");
  }

  const handleChange = useCallback((e, rowIndex, valueIndex) => {
    setLocalSelectedItem((prevItems) => {
      const newItems = [...prevItems];
      newItems[rowIndex][valueIndex] = e.target.value;
      return newItems;
    });
  }, []);

  useEffect(() => {
    const constructedEvent = {
      target: {
        name: "custom_data",
        value: isEmptyObject(localSelectedItem) ? {} : Object.fromEntries(localSelectedItem),
        type: "json",
      },
    };

    setEditedDetails(constructedEvent);
  }, [localSelectedItem]);

  useEffect(() => {
    if (prevRowKey !== rowKey) {
      setPrevRowKey(rowKey);
      initializeLocalSelectedItem();
    }
  }, [rowKey]);

  useEffect(() => {
    console.log("resetFlag", resetFlag);
    initializeLocalSelectedItem();
  }, [resetFlag]);

  return (
    <FieldWrapper label={label} inactive={disabled} description={description} fullWidth={true}>
      <form className={disabled ? "pointer-events-none opacity-70" : ""}>
        {localSelectedItem &&
          localSelectedItem.map((row, i) => (
            <Fragment key={getFieldKey(i, "row")}>
              <div className="flex justify-start flex-wrap items-start">
                <AnimatePresence>
                  {row &&
                    row.map((val, j) => (
                      <motion.div
                        className={`flex p-2 min-w-[350px] ${j === 0 ? "" : "flex-1"}`}
                        key={getFieldKey(i, j)}
                        initial={{ opacity: 0, height: 0, y: -10 }}
                        animate={{
                          opacity: 1,
                          height: "auto",
                          y: 0,
                          transition: {
                            height: { duration: 0.1 },
                            opacity: { duration: 0.1 },
                            y: { duration: 0.1 },
                          },
                        }}
                        exit={{
                          opacity: 0,
                          height: 0,
                          y: -10,
                          transition: {
                            height: { duration: 0.1 },
                            opacity: { duration: 0.1 },
                            y: { duration: 0.1 },
                          },
                        }}
                      >
                        <div className={`flex  ${j === 0 ? "w-[350px]" : "w-full"}`}>
                          <TextField
                            type={j === 0 ? "text" : "textarea"}
                            description={j === 0 ? "Key" : "Value"}
                            name={j === 0 ? "Key" : "Value"}
                            label={j === 0 ? "Key" : "Value"}
                            value={val}
                            onChange={(e) => handleChange(e, i, j)}
                          />
                        </div>
                      </motion.div>
                    ))}
                </AnimatePresence>
                <div
                  className={`flex items-center self-stretch ml-4 ${disabled ? "pointer-events-none opacity-30" : ""}`}
                >
                  <div
                    className="flex items-center justify-center w-6 h-6 bg-white/10 rounded-full cursor-pointer mr-2 "
                    onClick={() => deleteRow(i)}
                  >
                    <MinusIcon />
                  </div>
                  {i === localSelectedItem.length - 1 && (
                    <div
                      className="flex items-center justify-center w-6 h-6 bg-white/10 rounded-full cursor-pointer"
                      onClick={addRow}
                    >
                      <PlusIcon />
                    </div>
                  )}
                </div>
              </div>
              {localSelectedItem.length > 1 && (
                <div className="px-3 pt-2 pb-3 opacity-30">
                  <hr />
                </div>
              )}
            </Fragment>
          ))}
      </form>
    </FieldWrapper>
  );
}
