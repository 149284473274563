import { useState, useMemo } from "react";
import { createPortal } from "react-dom";
import { useLayout } from "contexts/LayoutContext";
import pageData from "common/pageConfig.json";

import FormField from "components/common/FormField";
import MenuBar from "components/common/MenuBar";
import Modal from "components/common/Modal";
import definitions from "common/definitions.json";

function getEditorConfig(config, editorTab) {
  return config?.editor[editorTab] ? config?.editor[editorTab] : undefined;
}

export default function OverridesForm({
  item,
  config,
  tab,
  onChange,
  newModel,
  buttonsMap,
  readPermitted,
  editPermitted,
  sandboxId,
  onDelete,
  showModal,
  setShowModal,
  onCheckToEnable,
  enabledFields,
}) {
  const { tableSize } = useLayout();

  const tabs = Object.keys(config?.editor);
  const defaultTab = useMemo(() => {
    const editorConfig = config?.editor || {};

    // Use default tab if set, otherwise, use the first tab
    return Object.keys(editorConfig).find((key) => editorConfig[key]?.default) ?? Object.keys(editorConfig)[0];
  }, [config]);

  const [editorTab, setEditorTab] = useState(defaultTab);
  const editorConfig = getEditorConfig(config, editorTab);

  function toggleModal(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowModal((prev) => !prev);
  }

  return (
    <div className="h-full pl-1">
      {tabs.length > 0 && (
        <MenuBar
          className="sticky top-0"
          buttons={buttonsMap}
          tabs={tabs}
          setActiveTab={setEditorTab}
          activeTab={editorTab}
        />
      )}

      <form className="relative p-2 min-h-[280px] h-full">
        <div className="h-full">
          {item && (
            <div className="flex flex-col h-full">
              {editorConfig?.fields.map((field) => (
                <div key={Object.entries(field).join()}>
                  <FormField
                    field={{
                      ...field,
                      type:
                        field?.editable === "hideAfterCreate" && newModel
                          ? field?.type || "text"
                          : field?.editable === "lockAfterCreate" && !newModel
                            ? "metadata"
                            : field.type || "text",
                    }}
                    rowKey={field.field}
                    fieldGroup={{ ...item }}
                    onChange={onChange}
                    originalValue={item}
                    description={
                      definitions?.schemas?.[editorConfig?.schemas?.GET ?? config.schema]?.[field.field]?.description
                    }
                    disabled={!editPermitted || !enabledFields.has(field.field)}
                    pageData={pageData[tab]}
                    sandboxId={item?.original_sandbox_id ?? item?.sandbox_id ?? sandboxId}
                    onCheckToEnable={onCheckToEnable}
                  />
                </div>
              ))}
              {editorTab === "Info" && editPermitted && (
                <div className="text-right mt-auto mb-24">
                  <button
                    onClick={toggleModal}
                    className={`text-white font-medium rounded-sm text-center inline-flex items-center z-10 px-3 py-1 gap-2 transition-colors duration-200 ease-in-out bg-rose-800 hover:bg-pink-950 ${tableSize}`}
                    title="Delete"
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </form>
      {showModal &&
        createPortal(
          <Modal
            isOpen={showModal}
            onClose={toggleModal}
            onConfirm={(e) => onDelete(e)}
            title={"Are you sure?"}
            message={`You are about to delete modifications for ${item?.name ?? item?.original?.name ?? item?.original?.loot_id ?? item?.loot_id}.`}
          />,
          document.body,
        )}
    </div>
  );
}
