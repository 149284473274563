import { useState, useEffect, useMemo, useReducer, useContext, useRef, Fragment } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import AccountContext from "contexts/AccountContext";
import { useAuth } from "contexts/AuthContext";
import { useLayout } from "contexts/LayoutContext";
import RightDrawer from "components/common/RightDrawer";
import { apiRequest, usePortalQuery, usePortalMutation, getErrorMessage } from "common/apiUtils";
import LoadingMask from "components/common/DynamicLoadingMask";
import MenuBar from "components/common/MenuBar";
import DropdownMenu from "components/common/DropdownMenu";
import { OpenDrawerIcon } from "assets/svgs";
import SearchAndSelect from "components/common/FormField/SearchAndSelect";
import DataTable from "components/common/datatable";
import OverridesForm from "components/common/Form/OverridesForm";
import {
  overridesCreateReducer,
  overridesPutReducer,
  overridesDeleteReducer,
  initialState,
} from "components/common/Form/overridesReducer";

import definitions from "common/definitions.json";
import CONFIG from "./overrides.config";

export default function PublishingOverrides() {
  const queryClient = useQueryClient();
  const { tableSize } = useLayout();

  const defaultTab = "Loot";
  const tabs = Object.keys(CONFIG.tabs);
  const { defaultDrawerWidth } = useLayout();
  const { currentStateData, permissionData, hasPermission } = useContext(AccountContext);
  const { getAccessToken } = useAuth();

  const columnHelper = createColumnHelper();

  let isResizing = useRef(false);

  const [selectedRow, setSelectedRow] = useState();
  const [rowSelectionModel, setRowSelectionModel] = useState({});
  const [activeTab, setActiveTab] = useState(defaultTab);

  const ROW_ID = useMemo(() => CONFIG.tabs[activeTab]?.rowId, [activeTab]);
  const TARGET_TYPE = useMemo(() => CONFIG.tabs[activeTab]?.targetType, [activeTab]);
  const PLURAL_ROW_ID = useMemo(() => CONFIG.tabs[activeTab]?.pluralRowId, [activeTab]);

  const [drawerLoadingMessage, setDrawerLoadingMessage] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(0);

  const [searchAndSelectValue, setSearchAndSelectValue] = useState();
  const [defaultTableData, setDefaultTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [error, setError] = useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [mutationStates, setMutationStates] = useState({});

  const [createState, createDispatch] = useReducer(overridesCreateReducer, initialState);
  const [updatesState, updatesDispatch] = useReducer(overridesPutReducer, initialState);
  const [deleteState, deleteDispatch] = useReducer(overridesDeleteReducer, initialState);

  const isDirty = useMemo(() => {
    return createState.length || updatesState.length || deleteState.length;
  }, [createState, updatesState, deleteState]);

  const [enabledFields, setEnabledFields] = useState(new Set());
  const [showModal, setShowModal] = useState(false);

  const drawerButtonsMap = [
    {
      name: "Close Drawer",
      icon: <OpenDrawerIcon className="rotate-180" />,
      fn: setDrawerWidth,
      args: [0],
    },
  ];

  const buttonsMap = [
    {
      name: "Table Size",
      icon: <DropdownMenu type="TableSize" />,
    },
    {
      name: "Open Drawer",
      icon: <OpenDrawerIcon className="" />,
      fn: setDrawerWidth,
      args: [350],
    },
  ];

  const valueKeys = useMemo(
    () =>
      CONFIG?.tabs[activeTab]?.editor?.["Info"]?.fields.reduce((acc, field) => {
        switch (field.type) {
          case "boolean":
            acc[field.field] = "value_boolean";
            break;
          case "singleDynamicSelect":
            acc[field.field] = "value_string";
            break;
          case "datetime":
            acc[field.field] = "value_datetime";
            break;
          default:
            acc[field.field] = "value_string";
            break;
        }
        return acc;
      }, {}),
    [activeTab],
  );

  const columns = useMemo(
    () =>
      CONFIG?.tabs[activeTab]?.editor?.["Info"]?.fields.map((field, i) => {
        return field.field === "name"
          ? columnHelper.accessor(field.field, {
              cell: (info) => info.getValue(),
              header: () => <span>{field.headerName}</span>,
              minSize: 250,
              maxSize: 500,
            })
          : columnHelper.accessor(field.field, {
              cell: (info) => info.getValue(),
              header: () => <span>{field.headerName}</span>,
              size: Number.MAX_SAFE_INTEGER,
            });
      }),
    [activeTab],
  );

  const defaultColumnVisibility = useMemo(
    () =>
      CONFIG?.tabs[activeTab]?.editor?.["Info"]?.fields.reduce((acc, field) => {
        acc[field.field] = field.tableVisibility ?? true;
        return acc;
      }, {}),
    [activeTab],
  );

  const [columnVisibility, setColumnVisibility] = useState(defaultColumnVisibility);

  const readPermitted =
    permissionData && currentStateData?.environment?.environmentId
      ? hasPermission(
          "environment:config:view",
          "environment",
          currentStateData?.environment?.orgId,
          currentStateData,
          permissionData,
        )
      : false;

  const editPermitted =
    permissionData && currentStateData?.environment?.environmentId
      ? hasPermission(
          "environment:config:edit",
          "environment",
          currentStateData?.environment?.environmentId,
          currentStateData,
          permissionData,
        ) ||
        hasPermission(
          "environmentOrg:config:edit",
          "environment",
          currentStateData?.environment?.environmentId,
          currentStateData,
          permissionData,
        )
      : false;

  async function enableOverlay() {
    const overlayResponse = await apiRequest(
      getAccessToken(),
      {
        path: "/v1/product/{product_id}/overlay",
        method: "POST",
      },
      {
        product_id: currentStateData?.product?.productId,
      },
      {
        name: `Default Publishing Overlay for product ${currentStateData?.product?.productId}`,
      },
    );

    const environmentOverlayResponse = await apiRequest(
      getAccessToken(),
      {
        path: "/v1/product/{product_id}/environment/{environment_id}/overlay",
        method: "POST",
      },
      {
        product_id: currentStateData?.product?.productId,
        environment_id: currentStateData?.environment?.environmentId,
      },
      {
        overlay_id: overlayResponse?.overlay_id,
      },
    );

    if (environmentOverlayResponse?.overlay_id) {
      queryClient.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] === "overlays" && query.queryKey[1] === currentStateData?.product?.productId) ||
          (query.queryKey[0] === "mods" && query.queryKey[1] === overlaysQuery.data?.[0]?.overlay_id),
      });
    }
  }

  const environmentQuery = useQuery({
    queryKey: ["environment", currentStateData?.environment?.environmentId],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        { path: `/v1/product/{product_id}/environment/{environment_id}`, method: "GET" },
        {
          product_id: currentStateData?.product?.productId,
          environment_id: currentStateData?.environment?.environmentId,
        },
      ),
    enabled: !!currentStateData?.product?.productId && !!currentStateData?.environment?.environmentId,
  });

  const sandboxQuery = useQuery({
    queryKey: ["sandbox", environmentQuery.data?.base_sandbox_id],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        {
          path: `/v1/org/{org_identifier}/product/{product_identifier}/sandbox/{sandbox_id}`,
          method: "GET",
        },
        {
          org_identifier: currentStateData?.org?.orgId,
          product_identifier: currentStateData?.product?.productId,
          sandbox_id: environmentQuery.data?.base_sandbox_id,
        },
      ),
    enabled:
      !!currentStateData?.org?.orgId &&
      !!currentStateData?.product?.productId &&
      !!environmentQuery.data?.base_sandbox_id,
  });

  const currentDataQuery = useQuery({
    queryKey: [CONFIG.tabs[activeTab]?.targetType, sandboxQuery?.data?.sandbox_id, searchAndSelectValue],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        definitions?.schemas[CONFIG.tabs[activeTab]?.schema]?.endpoints.find((endpoint) => endpoint.method === "get"),
        { sandbox_id: sandboxQuery?.data?.sandbox_id },
        null,
        null,
        {
          queryParams: searchAndSelectValue
            ? { page_size: 50, expand: "*", name: searchAndSelectValue }
            : { page_size: 50, expand: "*" },
        },
      ),
    enabled: !!sandboxQuery?.data?.sandbox_id,
  });

  const currentDataOptions = useMemo(() => {
    return currentDataQuery?.data?.data?.length > 0
      ? currentDataQuery?.data?.data?.map((currentData) => ({
          id: currentData?.[ROW_ID],
          title: currentData.name ?? currentData?.[ROW_ID],
        }))
      : [];
  }, [currentDataQuery.data]);

  const overlaysQuery = useQuery({
    queryKey: ["overlays", currentStateData?.product?.productId],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        { path: "/v1/product/{product_id}/environment/{environment_id}/overlay", method: "GET" },
        {
          product_id: currentStateData?.product?.productId,
          environment_id: currentStateData?.environment?.environmentId,
        },
      ),
    enabled: !!currentStateData?.product?.productId && !!currentStateData?.environment?.environmentId,
  });

  const modsQuery = useQuery({
    queryKey: ["mods", overlaysQuery.data?.[0]?.overlay_id],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        { path: "/api/v1/product/{product_id}/overlay/{overlay_id}/modification", method: "GET" },
        { product_id: currentStateData?.product?.productId, overlay_id: overlaysQuery?.data?.[0]?.overlay_id },
        null,
        null,
        { queryParams: { page_size: 5000, expand: "*" } },
      ),
    enabled: !!currentStateData?.product?.productId && !!overlaysQuery.data?.[0]?.overlay_id,
  });

  const replacements = useMemo(() => {
    return {
      sandbox_id: sandboxQuery?.data?.sandbox_id,
      product_id: currentStateData?.product?.productId,
      org_id: currentStateData?.org?.orgId,
      environment_id: currentStateData?.environment?.environmentId,
      overlay_id: overlaysQuery?.data?.[0]?.overlay_id ?? "",
    };
  }, [
    sandboxQuery?.data?.sandbox_id,
    currentStateData?.product?.productId,
    currentStateData?.org?.orgId,
    overlaysQuery?.data,
  ]);

  const updateMutation = usePortalMutation({
    queryClient,
    schema: "OverlayModificationUpdate",
    token: getAccessToken(),
    replacements,
    method: "put",
    onErrorCallback: (error) => {
      const errorMsg = getErrorMessage(error, CONFIG.fields);
      setError(errorMsg);
    },
  });

  const createMutation = usePortalMutation({
    queryClient,
    schema: "OverlayModificationCreate",
    token: getAccessToken(),
    replacements,
    method: "post",
    onErrorCallback: (error) => {
      console.log("createMutation error", error);
      const errorMsg = getErrorMessage(error, CONFIG.fields);
      setError(errorMsg);
    },
  });

  const deleteMutation = usePortalMutation({
    queryClient,
    schema: "OverlayModificationListResponse",
    token: getAccessToken(),
    replacements,
    method: "delete",
    onErrorCallback: (error) => {
      console.log("deleteMutation error", error);
      const errorMsg = getErrorMessage(error, CONFIG.fields);
      setError(errorMsg);
    },
  });

  function handleSave() {
    let mutationPromises = [];

    if (createState.length) {
      mutationPromises.push(createMutation.mutateAsync({ data: createState }));
    }
    if (updatesState.length) {
      mutationPromises.push(updateMutation.mutateAsync({ data: updatesState }));
    }
    if (deleteState.length) {
      const deleteIds = deleteState.map((mod) => mod.modification_id);
      const deleteIdsParam = deleteIds.map((id) => `modification_ids=${id}`).join("&");

      mutationPromises.push(deleteMutation.mutateAsync({ extraOptionsOverride: { queryParams: deleteIdsParam } }));
    }

    Promise.all(mutationPromises)
      .then((results) => {
        return results;
      })
      .catch((error) => {
        const errorMsg = getErrorMessage(error, CONFIG.fields);
        setError(errorMsg);
      })
      .finally(async () => {
        const isAnyPending = Object.values(mutationStates).some((state) => state === "pending");

        // Delay the success message to allow the Save/Cancel buttons to animate off
        // and Success to animate on
        const timeout1 = setTimeout(() => {
          setShowSuccessMessage(true);
          clearTimeout(timeout1);
        }, 200);
        const timeout2 = setTimeout(() => {
          setShowSuccessMessage(false);
          clearTimeout(timeout2);
        }, 2500);

        queryClient.refetchQueries({ queryKey: ["mods", overlaysQuery.data?.[0]?.overlay_id] });
        if (!isAnyPending) {
          setError();
          deleteDispatch({ type: "RESET_STATE" });
          updatesDispatch({ type: "RESET_STATE" });
          createDispatch({ type: "RESET_STATE" });
          await queryClient.refetchQueries({ queryKey: ["mods", overlaysQuery.data?.[0]?.overlay_id] });

          const dataWithModDetails = getDataWithModDetails(currentDataQuery?.data?.data, modsQuery.data?.data);
          processSelectedRow(
            dataWithModDetails.find(
              (row) =>
                row?.[ROW_ID] === selectedRow?.[ROW_ID] || row?.original?.[ROW_ID] === selectedRow?.original?.[ROW_ID],
            ),
          );
        }
      });
  }

  async function handleDelete(e) {
    e.preventDefault();
    e.stopPropagation();
    const itemId = selectedRow?.original?.item_id ?? selectedRow?.item_id;
    const filteredCreate = createState.filter((mod) => mod?.target_id !== itemId);
    createDispatch({ type: "UPDATE_STATE", payload: { value: filteredCreate } });

    const filteredUpdates = updatesState.filter((mod) => mod?.target_id !== itemId);
    updatesDispatch({ type: "UPDATE_STATE", payload: { value: filteredUpdates } });

    const modsToRemove = modsQuery.data?.data?.reduce((acc, mod) => {
      if (mod?.target_id === itemId) {
        acc.push(mod.modification_id);
      }
      return acc;
    }, []);

    if (!modsToRemove.length) {
      const filteredTableData = tableData.filter((row) => row?.[ROW_ID] !== itemId);
      setTableData(filteredTableData);

      const filteredDefaultTableData = defaultTableData.filter((row) => row?.[ROW_ID] !== itemId);
      setDefaultTableData(filteredDefaultTableData);

      setSelectedRow();
      setRowSelectionModel({});

      return;
    }

    const modsToRemoveParam = modsToRemove.map((id) => `modification_ids=${id}`).join("&");

    const response = await apiRequest(
      await getAccessToken(),
      { method: "DELETE", path: "/v1/product/{product_id}/overlay/{overlay_id}/modification" },
      replacements,
      null,
      null,
      { queryParams: modsToRemoveParam },
    );

    if (response.status === 204) {
      queryClient.refetchQueries({ queryKey: ["mods", overlaysQuery.data?.[0]?.overlay_id] });
      setSelectedRow();
      setRowSelectionModel({});
    }
    setShowModal(false);
  }

  function handleCancel() {
    setError();
    setTableData(defaultTableData);
    setSelectedRow();
    setRowSelectionModel({});
    deleteDispatch({ type: "RESET_STATE" });
    updatesDispatch({ type: "RESET_STATE" });
    createDispatch({ type: "RESET_STATE" });
  }

  function handleChange(data, fieldName) {
    const existingMod = modsQuery?.data?.data?.find(
      (mod) => mod.target_id === data[ROW_ID] && mod.field_name === fieldName,
    );

    if (existingMod) {
      const updateEntry = {
        target_id: data[ROW_ID],
        target_type: TARGET_TYPE,
        field_name: fieldName,
        [valueKeys[fieldName]]: data[fieldName],
        modification_id: existingMod?.modification_id,
      };

      let updates = updatesState.length ? updatesState : [];

      const updateIndex = updates.findIndex((mod) => mod.modification_id === updateEntry.modification_id);
      if (updateIndex > -1) {
        updates.splice(updateIndex, 1, updateEntry);
      } else {
        updates = [...updatesState, updateEntry];
      }

      updatesDispatch({ type: "UPDATE_STATE", payload: { value: updates } });
    } else {
      const newEntry = {
        target_id: data[ROW_ID],
        target_type: ROW_ID,
        field_name: fieldName,
        [valueKeys[fieldName]]: data[fieldName],
      };

      if (!createState.length) {
        createDispatch({ type: "UPDATE_STATE", payload: { value: [newEntry] } });
      } else {
        const existingCreate = createState.find(
          (mod) => mod.target_id === data[ROW_ID] && mod.field_name === fieldName,
        );
        if (existingCreate) {
          createDispatch({
            type: "UPDATE_STATE",
            payload: {
              value: createState.map((mod) =>
                mod.target_id === data[ROW_ID] && mod.field_name === fieldName ? newEntry : mod,
              ),
            },
          });
        } else {
          createDispatch({ type: "UPDATE_STATE", payload: { value: [...createState, newEntry] } });
        }
      }
    }

    const updatedRow = {
      ...(selectedRow?.original ? selectedRow.original : selectedRow),
      [fieldName]: data[fieldName],
    };

    setSelectedRow(updatedRow);
  }

  function addToTable(dataOption) {
    const fullObject = currentDataQuery?.data?.data.find((x) => x?.[ROW_ID] === dataOption.id);
    setTableData((prev) => [...prev, fullObject]);
    setRowSelectionModel({ [fullObject?.[ROW_ID]]: true });
    setSelectedRow(fullObject);
  }

  function onCheckToEnable(e, field, value) {
    const selectedRowId = selectedRow?.[ROW_ID] ?? selectedRow?.original?.[ROW_ID];

    if (e.target.checked) {
      setEnabledFields((prev) => new Set([...prev, field]));

      const existingCreateIndex = createState.findIndex(
        (mod) => mod.field_name === field && mod.target_id === selectedRowId && mod.target_type === TARGET_TYPE,
      );

      if (existingCreateIndex === -1) {
        createDispatch({
          type: "UPDATE_STATE",
          payload: {
            value: [
              ...createState,
              { target_id: selectedRowId, target_type: TARGET_TYPE, field_name: field, [valueKeys[field]]: value },
            ],
          },
        });
      }

      if (deleteState.some((mod) => mod.field_name === field && mod.target_id === selectedRowId)) {
        deleteDispatch({
          type: "UPDATE_STATE",
          payload: {
            value: deleteState.filter((mod) => !(mod.field_name === field && mod.target_id === selectedRowId)),
          },
        });
      }
    } else {
      setEnabledFields((prev) => new Set([...prev].filter((f) => f !== field)));

      if (updatesState.some((mod) => mod.field_name === field && mod.target_id === selectedRowId)) {
        updatesDispatch({
          type: "UPDATE_STATE",
          payload: {
            value: updatesState.filter((mod) => mod.field_name !== field && mod.target_id !== selectedRowId),
          },
        });
      }

      if (createState.some((mod) => mod.field_name === field && mod.target_id === selectedRowId)) {
        createDispatch({
          type: "UPDATE_STATE",
          payload: {
            value: createState.filter((mod) => mod.field_name !== field && mod.target_id !== selectedRowId),
          },
        });
      }

      const existingMod = modsQuery.data?.data?.find(
        (mod) => mod.field_name === field && mod.target_id === selectedRowId,
      );

      if (existingMod) {
        deleteDispatch({
          type: "UPDATE_STATE",
          payload: {
            value: [...deleteState, existingMod],
          },
        });
      }
    }
  }

  function processSelectedRow(row) {
    const enabledFieldsForRow = new Set();

    modsQuery.data?.data?.forEach((mod) => {
      if (mod?.target_id === (row?.original?.[ROW_ID] ?? row?.[ROW_ID])) {
        enabledFieldsForRow.add(mod.field_name);
      }
    });

    updatesState.forEach((mod) => {
      if (mod?.target_id === (row?.original?.[ROW_ID] ?? row?.[ROW_ID])) {
        enabledFieldsForRow.add(mod.field_name);
      }
    });

    createState.forEach((mod) => {
      if (mod?.target_id === (row?.original?.[ROW_ID] ?? row?.[ROW_ID])) {
        enabledFieldsForRow.add(mod.field_name);
      }
    });

    setEnabledFields(enabledFieldsForRow);
    setSelectedRow(row);
  }

  function getDataWithModDetails(lootData, modsData) {
    return lootData?.map((loot) => {
      const mods = modsData.filter((mod) => mod.target_id === loot?.[ROW_ID]);

      return mods.reduce(
        (acc, mod) => {
          const value = mod.value_string ?? mod.value_number ?? mod.value_boolean ?? mod.value_datetime;
          acc[mod.field_name] = value;

          return acc;
        },
        { ...loot },
      );
    });
  }

  useEffect(() => {
    if (Object.keys(rowSelectionModel).length > 0) {
      setDrawerWidth(defaultDrawerWidth);
    } else {
      setDrawerWidth(0);
    }
  }, [selectedRow, rowSelectionModel]);

  // On load, fetch the loot data, or whatever the tab is from each sandbox, given the target_id of each mod, then resplice the mod data back into the results and put them in the table
  useEffect(() => {
    if (!modsQuery?.data?.data?.length || !sandboxQuery?.data?.sandbox_id) {
      setDefaultTableData([]);
      setTableData([]);
      setSelectedRow();
      setRowSelectionModel({});
      return;
    }

    const targetIds = [...new Set(modsQuery.data.data.map((mod) => mod.target_id))];
    const lootIdsParam = targetIds.map((id) => `${PLURAL_ROW_ID}=${id}`).join("&") + "&expand=*";

    const fetchLootData = async () => {
      const endpoint = definitions?.schemas[CONFIG.tabs[activeTab]?.schema]?.endpoints.find(
        (endpoint) => endpoint.method === "get",
      );

      try {
        const allLootData = await apiRequest(
          getAccessToken(),
          endpoint,
          { sandbox_id: sandboxQuery?.data?.sandbox_id },
          null,
          null,
          { queryParams: lootIdsParam },
        );

        const dataWithModDetails = getDataWithModDetails(allLootData?.data, modsQuery.data?.data);

        setDefaultTableData(dataWithModDetails);
        setTableData(dataWithModDetails);
      } catch (error) {
        console.error("Error fetching loot data:", error);
      }
    };

    fetchLootData();
  }, [modsQuery?.data?.data, sandboxQuery?.data?.sandbox_id]);

  const loadMessage = "Retrieving the latest data, please wait...";

  return environmentQuery?.isPending || overlaysQuery?.isError || overlaysQuery?.isPending ? (
    <LoadingMask loadMessage={loadMessage} />
  ) : overlaysQuery?.data?.length === 0 ? (
    <div className="p-10 max-w-[700px] text-base leading-tight">
      <p className="text-zinc-300 mb-6">
        It looks like you don't have any overlays set up yet for this environment. This feature allows for your team to
        easily override values that exist in the sandbox that this environment uses. Enabling an overlay will invisibly
        generate a copy of the sandbox, but with the values provided in this table. The game will automatically point to
        this generated sandbox. There will be no difference in game performance.
      </p>
      <p className="mb-6">Would you like to enable overlays and use this Overrides section?</p>
      <div
        onClick={enableOverlay}
        className="bg-violet-600 text-white font-medium rounded-sm text-center inline-flex items-center z-10 py-2 px-3 gap-2 transition-colors duration-200 ease-in-out hover:bg-violet-500 cursor-pointer"
      >
        Enable Overlays
      </div>
    </div>
  ) : (
    <div className="relative flex flex-1 z-10">
      {drawerLoadingMessage && (
        <div className="absolute top-0 left-0 z-20 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="mt-20">
            <LoadingMask size={100} loadMessage={drawerLoadingMessage} />
          </div>
        </div>
      )}
      <div
        className="relative"
        style={{
          width: `calc(100% - ${drawerWidth}px)`,
          transition: isResizing.current ? "none" : "width 0.3s ease-in-out",
        }}
      >
        <MenuBar
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          buttons={buttonsMap}
          setSelectedRow={setSelectedRow}
          setRowSelectionModel={setRowSelectionModel}
        />
        <div className="flex items-center justify-end bg-[#121212] w-full py-2 px-2 border-l border-r border-b border-zinc-800 relative overflow-hidden">
          <div
            className={`flex gap-2 transition-all duration-300 relative z-20 ${
              isDirty && editPermitted ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
            }`}
          >
            <button
              onClick={handleCancel}
              className={`bg-zinc-600 text-white font-medium rounded-sm text-center px-1 py-1 transition-colors duration-200 ease-in-out hover:bg-zinc-700 w-20 ${tableSize}`}
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className={`bg-violet-600 text-white font-medium rounded-sm text-center px-1 py-1 transition-colors duration-200 ease-in-out hover:bg-violet-700 w-20 ${tableSize}`}
            >
              {updateMutation.isPending ? "Saving..." : "Save"}
            </button>
          </div>

          {showSuccessMessage && (
            <div className="absolute top-1/2 right-3 transform -translate-y-1/2 animation-fade-in-out">
              <div className="p-1 text-xs rounded-sm font-medium text-green-500 border border-green-500/20 bg-green-900/20">
                Success
              </div>
            </div>
          )}
        </div>
        <div
          className={`text-red-500 grid transition-all px-3 ${
            updateMutation.isError || !!error ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
          }`}
        >
          <p
            className={`overflow-hidden font-lato border-red-500/20 rounded-sm ${
              updateMutation.isError || !!error ? "p-2 border-2 mt-3" : "p-0 border-0"
            } box-border`}
          >
            {updateMutation.isError ? updateMutation.error : ""}
            {error && error.split("\n").map((error) => <Fragment key={error}>{error}</Fragment>)}
          </p>
        </div>
        {!currentDataOptions ? (
          <div className="mt-20 flex-1">
            <LoadingMask loadMessage={loadMessage} />
          </div>
        ) : (
          <div className="flex p-2 justify-between items-center">
            <div
              className={`w-[320px] ${
                currentDataOptions.length === 0 && !searchAndSelectValue ? "opacity-40 pointer-events-none" : ""
              }`}
            >
              <SearchAndSelect
                label="Loot"
                name="loot"
                onSelect={addToTable}
                onChange={setSearchAndSelectValue}
                options={currentDataOptions.filter(
                  (fullObject) => !tableData.some((x) => x?.[ROW_ID] === fullObject.id),
                )}
                required={false}
                disabled={false}
                placeholder="Select Loot"
                description="The loot to which overrides will be applied."
              />
            </div>
            <p className={`text-zinc-300 ${tableSize} font-medium px-1`}>
              <span className="pb-1 border-b border-b-zinc-300/20">Current Sandbox: {sandboxQuery?.data?.name}</span>
            </p>
          </div>
        )}
        <DataTable
          data={tableData}
          columns={columns}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          columnsWithSort={["loot_id", "name"]}
          setSelectedRow={processSelectedRow}
          selectedRow={selectedRow}
          setRowSelectionModel={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          defaultSortKey={CONFIG?.tabs[activeTab]?.rowId}
          rowId={CONFIG.tabs[activeTab]?.rowId}
          className="flex-1"
          readPermitted={readPermitted}
          editPermitted={editPermitted}
        />
      </div>
      <RightDrawer setWidthFn={setDrawerWidth} width={drawerWidth} isResizing={isResizing}>
        {selectedRow && (
          <OverridesForm
            item={selectedRow?.original || selectedRow}
            setRowSelectionModel={setRowSelectionModel}
            setDrawerLoadingMessage={setDrawerLoadingMessage}
            config={CONFIG?.tabs[activeTab]}
            tab={activeTab}
            setSelectedRow={processSelectedRow}
            buttonsMap={drawerButtonsMap}
            readPermitted={readPermitted}
            editPermitted={editPermitted}
            sandboxId={selectedRow?.original?.sandbox_id || sandboxQuery?.data?.sandbox_id}
            onChange={handleChange}
            onDelete={handleDelete}
            showModal={showModal}
            setShowModal={setShowModal}
            onCheckToEnable={onCheckToEnable}
            enabledFields={enabledFields}
          />
        )}
      </RightDrawer>
    </div>
  );
}
